// 15 - Redirection / Avec image de fond

.module-15-cta_bg-img {
    position: relative;
    overflow: hidden;
    padding-top: $pad-xlarge;
    padding-bottom: $pad-xlarge;
    @include break(tablet) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &.bg_orange_no-img {
        background-color: $orange;
    }
    
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-size: cover;
        z-index: 1;
        &.bg-img_blue {
            @include break(mobile) {
                background-image: url(../img/bg-blue_mobile.svg)!important;
            }
        }
        &.bg-img_orange {
            @include break(mobile) {
                background-image: none!important;
            }
        }
    }
    .container {
        position: relative;
        z-index: 5;

        .content {
            text-align: center;
            color: $white;
            width: 100%;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;

            .txt {
                padding-top: $pad-xsmall;
            }
        }

        .link_container {
            display: flex;
            justify-content: center;
            padding-top: 60px;
            @include break(tablet) {
                flex-direction: column;
                padding-top: $pad-med_mobile;
            }

            .cta-card {
                border-radius: 12px;
                background-color: $white;
                overflow: hidden;
                padding: 24px 34px;
                width: 350px;
                height: 180px;
                margin-left: 10px;
                margin-right: 10px;
                @include break(mobile) {
                    width: 100%;
                    max-width: 350px;
                    height: auto;
                    margin-bottom: $pad-xxsmall;
                    margin-left: 0px;
                    margin-right: 0px;
                }
                

                &_icon {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.4s;
                    @include break(mobile) {
                        flex-direction: row;
                        text-align: left;
                        align-items: center;
                    }
                    
                    .icon {
                        width: 50px;
                        height: 50px;
                        position: relative;
                        margin-bottom: $pad-xsmall;
                        img {
                            @include bg-img;
                            object-fit: contain;
                            transition: all 0.4s;
                        }
                        @include break(mobile) {
                            width: 40px;
                            height: 40px;
                            margin-right: 32px;
                            flex-shrink: 0;
                            margin-bottom: 0;
                        }
                    }

                    .title-h5 {
                        @include break(mobile) {
                            width: 100%;
                        }
                    }

                    &:hover {
                        background-color: $orange;
                        color: $white;
                        .icon img {
                            filter: grayscale(1) brightness(2);
                        }
                    }
                }

                &_arrow {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    transition: all 0.4s;
                    @include break(mobile) {
                        flex-direction: row;
                        align-items: center;
                        font-size: 1.12em;
                    }
                    .round_btn {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        background-color: $orange;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.4s;
                        @include break(mobile) {
                            flex-shrink: 0;
                            margin-left: 32px;
                        }
                        svg {
                            transition: all 0.4s;
                        }
                    }

                    &:hover {
                        color: $orange;
                        .round_btn { 
                            transform: scale(1.1);
                            svg {
                                transform: scale(0.8);
                            }
                        }
                    }
                }
            }
        }
    }

    &.layout-row {
        .container {
            display: flex;
            @include break(mobile) {
                flex-direction: column
            }

            .content {
                text-align: left;
                width: 100%;
                flex-shrink: 1;
                padding-right: 15%;
                max-width: 100%;
                margin-left: 0;
                margin-right: 0;
                @include break(mobile) {
                    padding-right: 0;
                    padding-bottom: $pad-med_mobile;
                }
            }

            .link_container {
                padding-top: 0;
            }
        }
    }
}



.post-type-archive-pages_business, .single-pages_business {

    .module-15-cta_bg-img .container .link_container .cta-card_arrow .round_btn {
        background-color: $business;
    }
    .module-15-cta_bg-img .container .link_container .cta-card_arrow:hover {
        color: $business;
    }

    .module-15-cta_bg-img .bg.bg-img_orange {
        background-image: none!important;
        background-color: $business;
    }
}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-14-cta_simple-arrows {
        background-color: $event;
        .cta_container .links_container a svg path{
            fill: $white;
        }
        .cta_container .links_container a:hover {
            color: $blue;
            svg path{
                fill: $blue;
            }
        }
    }

}