.module-32-travel-dest {

    .travel-dest {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .small-card_dest {
        position: relative;
        width: 316px;
        height: 316px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 12px;
        overflow: hidden;
        padding: 20px;
        margin-bottom: 20px;
        img {
            @include bg-img;
            z-index: 1;
            transition: all 0.8s;
        }
        .filter {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(71, 65, 60, 0.40);
            mix-blend-mode: multiply;
            z-index: 2;
        }
        .title-h4 {
            position: relative;
            color: $white;
            z-index: 3;
        }

        &:hover img {
            transform: translate(-50%, -50%) scale(1.05);
        }

        &.ghost {
            height: 0;
            padding: 0;
            opacity: 0;
        }
    }

    .travel-dest_tab {
        overflow: hidden;
        .tab_nav {
            overflow-x: scroll;
            overflow-y: hidden;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            padding-bottom: 40px;

            .tab_nav-inner {
                display: flex;
                .tab_name {
                    margin-right: 30px;
                    font-size: 1.2em;
                    @include font-medium;
                    flex-shrink: 0;
                    position: relative;
                    transition: all 0.4s;
                    padding-bottom: 9px;
                    cursor: pointer;

                    &::after {
                        content: " ";
                        position: absolute;
                        bottom: 4px;
                        left: 0;
                        width: 0%;
                        height: 4px;
                        background-color: $dark;
                        transition: all 0.4s;
                    }

                    &:hover, &.active {
                        color: $orange;
                        &::after { 
                            width: 100%;
                            background-color: $orange;
                        }
                    }

                    &.active {
                        pointer-events: none;
                    }
                }
                .tab_ghost {
                    flex-shrink: 0;
                    width: calc((100vw - 1325px) / 2);
                    @include break(container){
                        width: 5%;
                    }
                }
            }
        }

        .tab_container {
            position: relative;
            height: 1000px;
            .tab {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                display: none;
                @include break(mobile){
                   flex-direction: column;
                   align-items: center;
                }
                .small-card_dest {
                    margin-bottom: 20px;
                    margin-right: 10px;
                    scroll-margin: 0;
                    @include break(mobile){
                        margin-right: 0px;
                    }
                    
                }

                &:first-of-type {
                    display: flex;
                }
            }
        }

    }
}