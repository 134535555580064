.template-voyage {
    padding-top: 124px;

    .hero-voyage {
        position: relative; 
        z-index: 1;
        .img {
            position: relative;
            width: 100%;
            height: 490px;
            @include break(tablet){
                height: 375px;
            }
    
            img {
                @include bg-img;
            }
        }

        .notif {
            position: absolute;
            z-index: 5;
            right: 5%;
            bottom: 90px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-direction: column;

            .text_notif {
                background-color: $orange;
                border-radius: 10px;
                width: 245px; 
                padding: 13px 22px;
                color: $white;
                font-size: 13px;
                margin-bottom: 10px;
                @include font-light;
                display: none;

                a {
                    display: block;
                    @include font-medium;  
                }
            }
            
            .click_notif {
                width: 47px;
                height: 47px;
                border-radius: 50%;
                position: relative;
                background-color: $orange;
                cursor: pointer;
                transition: all 0.4s;
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.4s;
                }
                .cross {
                   opacity: 0;
                   transform: translate(-50%,-50%) scale(0.6);
                }
                
                &.open {
                    .cross {
                        opacity: 1;
                        transform: translate(-50%,-50%) scale(1);
                     }
                     .bell {
                        opacity: 0;
                        transform: translate(-50%,-50%) scale(0.6); 
                     }
                }

                &:hover {
                    transform: scale(1.1);
                    svg {
                        transform: translate(-50%,-50%) scale(0.9);
                    }
                }
            }
        }

        .click_galerie {
            position: absolute;
            z-index: 5;
            left: 5%;
            bottom: 90px;
            display: flex;  
            background-color: $white;
            border-radius: 42px;
            padding: 16px 32px 12px 32px;
            align-items: center;
            color: $orange;
            font-size: 14px;
            @include font-medium;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.4s;
            svg {
                margin-right: 14px;
                path {
                    transition: all 0.4s;
                }
            }

            &:hover {
                background-color: $orange;
                color: $white;
                svg path {
                     fill: $white;
                }
            }
        }
    }

    .content-info {
        position: relative;
        z-index: 10;
        margin-top: -55px;
        padding-top: 40px;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
        background-color: $off-white;

        .container {
            display: flex;
            justify-content: space-between;
            padding-bottom: 40px;
            border-bottom: 1px solid $dark-mole;
            margin-bottom: 80px;
            @include break(tablet) {
                flex-direction: column;
                justify-content: center;
            }
        }

        .content {
            flex-shrink: 1;
            width: 100%;
        }

        .breadcrumb a, .breadcrumb p {
            color: $dark-grey;
        }
        .breadcrumb a:hover {
            color: $orange;
        }

        h1 {
            padding-top: 25px;
            padding-bottom: 5px;
        }

        .rate {
            @include break(tablet) {
                margin-bottom: 20px;
            }
        }

        .tag_wrapper {
            padding-top: 45px;
            display: flex;
            flex-wrap: wrap;
            .tag {
                flex-shrink: 0;
                padding: 5px 10px;
                border-radius: 6px;
                padding: 5px 10px;
                @include font-medium;
                font-size: 15px;
                background-color: $mole;
                color: $orange;
                margin-right: 8px;

                &.deal {
                    background-color: $orange;
                    color: $white;
                }
            }
        }

        .agent_wrap {
            padding-top: 45px;
            display: flex; 
            flex-wrap: wrap;
            .logo {
                height: 75px;
                width: auto;
                margin-right: 20px;
            }
        }

        .price_container {
            flex-shrink: 0;
            width: 320px;
            margin-left: 5%;
            @include break(tablet) {
                margin-left: 0;
                width: 100%;
            }

            .spin {
                transform-origin: 50%;
                animation:spin 8s linear infinite;
            }

            p.no_price {
                padding-top: 50px;
                padding-bottom: 28px;
                font-size: 1.4em;
                @include break(tablet) {
                    padding-top: 9px;
                    padding-bottom: 45px;
                }
            }

            .price_box {
                position: relative;
                width: 100%;
                min-height: 290px;
                background-color: $orange;
                color: $white;
                border-radius: 12px;
                padding: 40px 31px;
                text-align: center;
                @include break(tablet) {
                   text-align: left;
                   min-height: 235px;
                }
                
                .flex {
                    display: flex;
                }

                .logo-price {
                    position: absolute;
                    width: 88px;
                    height: 88px;
                    left: -20px;
                    top: -20px;
                    @include break(tablet) {
                        position: initial;
                        width: 60px;
                        height: 60px;
                        left: 0px;
                        top: 0px;
                        margin-right: 20px;
                    }
                }

                .text_small {
                    font-size: 15px;
                }
                .price {
                    padding-top: 3px;
                    font-size: 40px;
                    @include font-medium;
                }
                .price_full {
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.50);
                    text-decoration: line-through;
                    @include font-medium;
                }
                .text_taxe {
                    @include font-medium;
                    font-size: 15px;
                    margin-bottom: 30px;
                }
                .btn_white {
                    align-items: center;
                    background: linear-gradient(to left, $white, $white 50%, $orange 75%, $orange 100%);
                    background-size: 500% 100%;
                    background-position-x: 100%;
                    color: $orange;
                    border: 1px solid $white;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg path {
                        transition: all 0.4s;
                    }
                    &:hover {
                        background-position-x: 0%;
                        color: $white;
                        svg path {
                            fill: $white;
                        }
                    }
                }
            }
        }

    }

    .content-description .container {
        display: flex;
        justify-content: space-between;
        @include break(tablet){
            flex-direction: column;
        }

        .content {
            flex-shrink: 1;
            width: 100%;

            .text {
                margin-bottom: 50px;
            }

            .collapse_container {
                .collapse {
                    padding: 28px 40px;
                    background-color: $mole;
                    border-radius: 12px;
                    margin-bottom: $pad-xsmall;
                    @include break(mobile){
                        padding: 28px 26px;
                    }
        
                    .collapse_title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        .title-h6 {
                            width: 75%;
                            transition: all 0.4s;
                            @include break(mobile){
                                width: calc(100% - 40px);
                                font-size: 20px;
                            }
                        }
                        .plus {
                            width: 28px;
                            height: 28px;
                            position: relative;
                            div {
                                width: 15px;
                                height: 3px;
                                background-color: $dark;
                                @include absolute-center(center);
                                transition: all 0.4s;
        
                                &.line2 {
                                    transform: translate(-50%,-50%) rotate(90deg);
                                }
                            }
                        }
        
                        &:hover {
                            .title-h6 {
                                color: $orange;
                            }
                            .plus {
                                div {
                                    background-color: $orange;
                                }
                            }
                        }
                    }
        
                    .collapse_content {
                        overflow: hidden;
                        display: none;
                        .collapse_content-inner {
                            padding-top: $pad-xsmall;
                            font-size: 15px;
                        }
                    }
        
                    &.active {
                        .title-h6 {
                            color: $orange;
                        }
                        .plus {
                            div {
                                background-color: $orange;
                                &.line2 {
                                    transform: translate(-50%,-50%) rotate(0deg);
                                }
                            }
                        }
                    }
        
                    &:first-of-type {
                        .collapse_content {
                            display: block;
                        }
                    }
                }
            }

            .galerie_min_wrap {
                padding-top: 80px;

                .galerie_min {
                    padding-top: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .img_container {
                        position: relative;
                        width: 32.5%;
                        padding-bottom: 21%;
                        border-radius: 20px;
                        overflow: hidden;
                        margin-bottom: 12px;
                        cursor: pointer;
                        @include break(tablet){
                            width: 48.5%;
                            padding-bottom: 35%;
                        }
                        img {
                            @include bg-img;
                        }
                    }

                    .click_galerie {
                        width: 32.5%;
                        padding-bottom: 21%;
                        border-radius: 20px;
                        overflow: hidden;
                        margin-bottom: 12px;
                        background-color: $orange;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 30px;
                        text-align: center;
                        color: $white;
                        cursor: pointer;
                        @include break(tablet){
                            width: 48.5%;
                            padding:15px;
                            font-size: 15px;
                        }
                        svg {
                            transition: all 0.4s;
                            @include break(tablet){
                                width: 35px;
                                height: 34px;
                            }
                        }
        
                        &:hover {
                            svg {
                                transform: scale(1.1);
                            }
                        }
                    }

                    .ghost {
                        width: 32.5%;
                        @include break(tablet){
                            width: 48.5%;
                        }
                    }
                }
            }
        }
        .side {
            flex-shrink: 0;
            width: 320px;
            margin-left: 10%;
            @include break(tablet){
                width: 100%;
                margin-left: 0%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-bottom: 50px;
            }
            &.desktop-tablet {
                @include break(tablet){
                    display: none;
                }
            }

            .info {
                padding-bottom: 25px;
                @include break(tablet){
                    width: 48%;
                    flex-shrink: 0;
                    flex-grow: 0;
                }
                .icon {
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    margin-bottom: 5px;
                    img {
                        margin-right: 6px;
                        width: 18px;
                        margin-bottom: 3px;
                    }
                }
                p {
                    @include font-medium;
                }
            }

            .assurance {
                width: 269px;
                height: 112px;
                background-color: $orange;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px;
                color: $white;
                border-radius: 12px;
                font-size: 20px;
                margin-top: 45px;
                @include font-medium;
                @include break(tablet){
                    width: 100%;
                }
                span {
                    width: 155px;
                }
                svg {
                    transition: all 0.4s;
                }

                &:hover {
                    svg {
                        transform: scale(1.1);
                    }
                }
            }

            .brochure {
                width: 269px;
                height: 112px;
                background-color: $white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px;
                color: $orange;
                border-radius: 12px;
                font-size: 20px;
                @include font-medium;
                margin-top: 10px;
                @include break(tablet){
                    width: 100%;
                }
                span {
                    width: 155px;
                }
                svg {
                    transition: all 0.4s;
                }

                &:hover {
                    svg {
                        transform: scale(1.1);
                    }
                }
            }
        }
        
    }

    .galerie_img {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999;
        display: none;

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $off-white;
            z-index: 0;
        }

        .close_btn {
            position: absolute;
            z-index: 4;
            top: 25px;
            right: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
                display: block;
                font-style: italic;
                font-size: 13px;
                @include font-light;
                margin-right: 8px;
                transition: all 0.4s;
            }
            svg {
                width: 15px;
                height: 15px;
                transition: all 0.4s;
            }
            &:hover {
                color: $orange;
                svg {
                    transform: scale(1.1);
                }
            }
        }

        .galerie_container {
            position: absolute;
            top: 8%;
            left: 8%;
            right: 8%;
            bottom: 8%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 2;

            .arrow {
                flex-shrink: 0;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;
                position: relative;
                transition: all 0.4s;
                cursor: pointer;
                svg {
                    transition: all 0.4s;
                }
                @include break(tablet) {
                    margin-left: 10px;
                    margin-right: 10px;
                    margin-top: 35px;
                }
                &:hover {
                    transform: scale(1.1);
                    svg {
                        transform: scale(0.8);
                    }
                }
        
                &.not_active {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
            .arrow_left {
               margin-right: 5%;
            }
            .arrow_right {
                margin-left: 5%;
            }

            .galerie {
                flex-shrink: 1;
                width: 100%;
                height: 100%;
                position: relative;
                
                .img_container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    pointer-events: none;
                    &:first-of-type {
                        opacity: 1;
                    }
                    img {
                        @include bg-img;
                        object-fit: contain;
                    }
                }
            }

            .slide_nb {
                position: absolute;
                bottom: -2.5%;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                align-items: center;
                font-size: 13px;
                font-style: italic;
                @include font-light;
            }
        }
    }

    .itineraire_wrap {
        margin-top: 120px;
        @include break(tablet) {
            margin-top: 60px;
        }
        .itineraire_container {
            display: flex;
            @include break(tablet) {
                flex-direction: column-reverse;
            }
        }
        .left {
            padding-top: $pad-xlarge;
            padding-bottom: $pad-xlarge;
            padding-left: calc((100vw - #{$container}) / 2);
            width: 60%;
            padding-right: 5%;

            @include break(container){
                padding-left: 5%;
            } 
            @include break(tablet) {
                padding-top: 60px;
                padding-bottom: 60px;
                width: 100%;
            }

            .day {
                padding-top: 60px;
                @include break(tablet) {
                    padding-top: 40px;
                }

                .day_title {
                    color: $orange;
                    padding-bottom: 12px;
                }

                .txt {
                    padding-top: 16px;
                }

                .img_container {
                    margin-top: 30px;
                    overflow: hidden;
                    border-radius: 12px;
                    position: relative;
                    padding-bottom: 59%;
                    img {
                        @include bg-img;
                    }
                }
            }

            .btn {
                margin-top: 80px;
            }
        }

        .right {
            width: 40%;
            flex-shrink: 1;
            background-color: transparent;
            position: relative;
            @include break(tablet) {
                width: 100%;
            }
            .carte_container {
                position: sticky;
                top: 0px;
                left: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100vh;
                @include break(tablet) {
                    position: initial;
                    height: auto;
                }

                img {
                    box-shadow: 0px 4px 25px rgba($dark-grey, 0.5);
                }
            }
        }
    }
}