.template-faq {

    .module-19-faq {

        h2 {
            padding-bottom: 40px;
        }

        .collapse_container {
            padding-bottom: 100px;
        }
    }

}
