.template-promotions {

    .promotions {
        background-color: $mole;
        padding-top: 70px;
        padding-bottom: 70px;
        @include break(tablet){
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .promo_card {
            border-radius: 12px;
            overflow: hidden;
            background-color: $white;
            width: 316px;
            min-height: 464px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 20px;
            position: relative;
            transition: all 0.4s;
            border: 1px solid rgba($color: $dark-mole, $alpha: 0);
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
    
            .img_container {
                position: relative;
                overflow: hidden;
                padding-bottom: 101%;
    
                img {
                    @include bg-img;
                }
            }
    
            .content {
                height: 100%;
                flex-shrink: 1;
                padding: 16px 20px 18px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
    
                .links {
                    display: flex;
                    align-items: center;
                }
    
                a {
                    display: block;
                    margin-right: 10px;
                    transition: all 0.4s;
    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
    
            &-ghost {
                padding: 0;
                height: 0;
                min-height: 0;
                opacity: 0;
                background-color: transparent;
            }
        }
    
        .promo_grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-left: -10px;
            margin-right: -10px;
            @include break(mobile) {
                margin-left: 0px;
                margin-right: 0px;
                flex-direction: column;
                align-items: center;
            }
    
            .promo_card { 
                @include break(mobile) {
                    flex-direction: row;
                    align-items: center;
                    height: auto;
                    min-height: 160px;
                    width: 100%;
                    max-width: 400px;
                    min-width: 0;
                    margin-left:0;
                    margin-right: 0;
    
                    .img_container {
                        width: 50%;
                        padding-bottom: 50%;
                    }
    
                    .content {
                        width: 50%;
                        justify-content: center;
                        h5 {
                            font-size: 1.15em;
                        }
                        .links {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}