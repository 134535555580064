.module-24-top {

    .container {
        display: flex;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
        }

        .title_top {
            padding-bottom: 0;
            width: 35%;
            min-width: 350px;
            padding-right: 0;
            @include break(tablet) {
                width: 100%;
            }

            .btn {
                margin-top: 40px;
            }
        }

        .slider_top-dest_container {
            width: 55%;
            flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include break(tablet) {
                margin-top: 60px;
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;
            }

            .arrow {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;
                position: relative;
                transition: all 0.4s;
                margin-left: 14px;
                cursor: pointer;
                svg {
                    transition: all 0.4s;
                }
                @include break(tablet) {
                    margin-left: 10px;
                    margin-right: 10px;
                    margin-top: 35px;
                }
                &:hover {
                    transform: scale(1.1);
                    svg {
                        transform: scale(0.8);
                    }
                }
        
                &.not_active {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }

            .arrow_left {
                @include break(tablet) {
                    order: 2;
                }
            }
            .arrow_right {
                @include break(tablet) {
                    order: 3;
                }
            }

            .slider_top-dest {
                width: calc(80% - 120px);
                padding-bottom: 83.5%;
                position: relative;
                @include break(tablet) {
                    width: 80%;
                    padding-bottom: 109%;
                }

                .dest_card {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    overflow: hidden;
                    pointer-events: none;
                    opacity: 0;
                    transition: none;
                    cursor: pointer;
                    transform-origin: center;

                    .img_container {
                        position: relative;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        img {
                            @include bg-img;
                        }
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        background: rgba(71, 65, 60, 0.0);
                        backdrop-filter: blur(0px);
                        transition: backdrop-filter 0.6s;
                    }

                    .content {
                        opacity: 0;
                        text-align: center;
                        z-index: 3;
                        @include absolute-center(center);
                        .tag {
                            padding: 5px 8px 3px 8px;
                            background-color: $white;
                            border-radius: 6px;
                            color: $orange;
                            font-size: 13px;
                            margin-bottom: 15px;
                            display: block;
                            width: fit-content;
                            margin-left: auto;
                            margin-right: auto;
                            @include font-medium;
                        }
                        h4 {
                            color: $white;
                        }
                    }

                    &:hover {
                        .overlay {
                            backdrop-filter: blur(5px);
                        }
                    }

                    &:first-of-type {
                        opacity: 1;
                        pointer-events: all;
                        z-index: 2;
                        transform: rotate(0deg);
                        .overlay {
                            background: rgba(71, 65, 60, 0.40);
                        }
                        .content {
                            opacity: 1;
                        }
                    }
                    &:nth-of-type(2) {
                        opacity: 1;
                        transform: rotate(6deg);
                    }
                }
            }
        }
    }

}