// Module - 19 - FAQ

.module-19-faq {
    .collapse_container {
        .collapse {
            padding: 28px 40px;
            background-color: $mole;
            border-radius: 12px;
            margin-bottom: $pad-xsmall;
            @include break(mobile){
                padding: 28px 26px;
            }

            .collapse_title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .title-h6 {
                    width: 75%;
                    transition: all 0.4s;
                    padding-bottom: 0px;
                    @include break(mobile){
                        width: calc(100% - 40px);
                        font-size: 20px;
                    }
                }
                .plus {
                    width: 28px;
                    height: 28px;
                    position: relative;
                    div {
                        width: 15px;
                        height: 3px;
                        background-color: $dark;
                        @include absolute-center(center);
                        transition: all 0.4s;

                        &.line2 {
                            transform: translate(-50%,-50%) rotate(90deg);
                        }
                    }
                }

                &:hover {
                    .title-h6 {
                        color: $orange;
                    }
                    .plus {
                        div {
                            background-color: $orange;
                        }
                    }
                }
            }

            .collapse_content {
                overflow: hidden;
                display: none;
                .collapse_content-inner {
                    padding-top: $pad-xsmall;
                    font-size: 15px;
                }
            }

            &.active {
                .title-h6 {
                    color: $orange;
                }
                .plus {
                    div {
                        background-color: $orange;
                        &.line2 {
                            transform: translate(-50%,-50%) rotate(0deg);
                        }
                    }
                }
            }

            &:first-of-type {
                .collapse_content {
                    display: block;
                }
            }
        }
    }

    &.type-col2 {
        .container {
            display: flex;
            @include break(tablet){
                flex-direction: column;
            }

            .title_top {
                width: 430px;
                padding-right: 0;
                margin-right: 7%;
                flex-shrink: 0;
                @include break(tablet){
                    width: 100%;
                }
            }

            .collapse_container {
                width: 100%;
                flex-shrink: 1;
            }
        }
    }
}


.post-type-archive-pages_business, .single-pages_business {

    .module-19-faq .collapse_container .collapse.active .title-h6, .module-19-faq .collapse_container .collapse .collapse_title:hover .title-h6 {
        color: $business;
    }
    .module-19-faq .collapse_container .collapse.active .collapse_title .plus div, .module-19-faq .collapse_container .collapse .collapse_title:hover .plus div {
        background-color: $business;
    }
}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-19-faq .collapse_container .collapse.active .title-h6, .module-19-faq .collapse_container .collapse .collapse_title:hover .title-h6 {
        color: $event;
    }
    .module-19-faq .collapse_container .collapse.active .collapse_title .plus div, .module-19-faq .collapse_container .collapse .collapse_title:hover .plus div {
        background-color: $event;
    }

}