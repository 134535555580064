.template-archive-blogue {
    strong {
        color: $orange;
    }
    .hero-blog {
        margin-top: 45px;
        height: 490px;
        background-color: $orange;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: $white;
        padding-top: 100px;
        padding-bottom: 55px;
        @include break(tablet) {
            padding-bottom: 60px;
        }
    }
    
    .top_article_container {
        overflow: hidden;
        position: relative;
        z-index: 10;
        margin-top: -55px;
        padding-top: 80px;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
        background-color: $off-white;
        padding-bottom: 180px;
        @include break(tablet) {
            padding-top: 60px;
            padding-bottom: 80px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        .top_article {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include break(tablet) {
                flex-direction: column;
            }

            .img_container {
                width: 45%;
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                padding-bottom: 32%;
                @include break(tablet) {
                    width: 100%;
                    padding-bottom: 66.6%;
                    margin-bottom: 40px;
                }
                img {
                    @include bg-img;
                }
                .tag_wrap {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    display: flex;
                    .tag {
                        border-radius: 6px;
                        background-color: $orange;
                        padding: 5px 8px 3px 8px;
                        margin-left: 8px;
                        font-size: 13px;
                        @include font-medium;
                        color: $white;
                    }
                }
            }
            .content {
                width: 48.5%;
                @include break(tablet) {
                    width: 100%;
                }

                .desc {
                    padding-top: 20px;
                    display: -webkit-box;
                    width: 100%;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @include break(tablet) {
                        padding-top: 10px;
                        -webkit-line-clamp: 5;
                    }
                }

                .info {
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                    color: $dark-grey;

                    .date {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        padding-right: 12px;
                        svg {
                            margin-bottom: 2px;
                            margin-right: 6px;
                            path {
                                fill: $dark-grey;
                            }
                        }
                    }
                    .time {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        padding-left: 12px;
                        svg {
                            margin-bottom: 2px;
                            margin-right: 6px;
                            path {
                                fill: $dark-grey;
                            }
                        }
                    }
                }

                .link {
                    margin-top: 36px;
                    @include break(tablet) {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .articles_container {
        padding-top: 60px;
        padding-bottom: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include break(tablet) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .card_article {
            width: calc(50% - 21px);
            margin-bottom: 80px;
            @include break(mobile) {
                width: 100%;
            }

            .img_container {
                width: 100%;
                padding-bottom: 66.25%;
                position: relative;
                border-radius: 12px;
                overflow: hidden;
                background-color: $orange;

                img {
                    @include bg-img;
                    transition: all 0.4s;
                }

                .tag_wrap {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    display: flex;
                    .tag {
                        border-radius: 6px;
                        background-color: $orange;
                        padding: 5px 8px 3px 8px;
                        margin-left: 8px;
                        font-size: 13px;
                        @include font-medium;
                        color: $white;
                    }
                }
            }

            .content {
                padding-top: 25px;

                .txt {
                    padding-top: $pad-xxsmall;
                    display: -webkit-box;
                    width: 100%;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: 15px;
                    @include break(tablet) {
                        padding-top: 10px;
                        -webkit-line-clamp: 5;
                    }
                    strong {
                        color: $orange;
                    }
                }

                .info {
                    display: flex;
                    align-items: center;
                    color: $dark-grey;
                    padding-top: $pad-xxsmall;

                    .date {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        padding-right: 12px;
                        svg {
                            margin-bottom: 2px;
                            margin-right: 6px;
                            path {
                                fill: $dark-grey;
                            }
                        }
                    }
                    .time {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        padding-left: 12px;
                        svg {
                            margin-bottom: 2px;
                            margin-right: 6px;
                            path {
                                fill: $dark-grey;
                            }
                        }
                    }
                }

                .link {
                    padding-top: 20px;
                }
            }

            &:hover {
                color: $dark;
                strong {
                    color: $orange;
                }
                .img_container img {
                    transform: translate(-50%,-50%) scale(1.05);
                }
                .link {
                    color: $dark-orange;
                    svg {
                    margin-left: 18px;
                    }
                    path {
                    fill: $dark-orange;
                    }
                }
            }
        }
    }

    .load-more_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 140px;
        @include break(tablet) {
            padding-bottom: 80px;
        }
        p {
            font-size: 13px;
            color: #5D5D5A;
        }
        .load_more {
            cursor: pointer;
            margin-top: 15px;
            color: $orange;
            display: flex;
            font-size: 14px;
            @include font-medium;
            text-transform: uppercase;
            align-items: center;
            transition: all 0.3s;
            svg {
              margin-left: 12px;
              margin-bottom: 2px;
              transition: all 0.3s;
              path {
                transition: all 0.3s;
              }
            }
          
            &:hover {
              color: $dark-orange;
              path {
                fill: $dark-orange;
              }
            }
        }
    }
}

.search-results {
    .top_article_container {
        padding-top: 120px;
        padding-bottom: 20px;
        @include break(tablet) {
            padding-top: 80px;
            padding-bottom: 0px;
        }
    }
}