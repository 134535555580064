// Module - 21 - Carrousel / Chronologie

.module-21-slider_chrono {
    position: relative;
    overflow: hidden;

    .slider_container {
        height: 100vh;
        width: 100vw;
        position: relative;
        @include break(tablet) {
            max-height: 700px;
        }

        .slider-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            pointer-events: none;

            .slider-img_slide {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: none;
                overflow: hidden;
                position: absolute;
                img {
                    @include bg-img;
                    filter: grayscale(0.7);
                }

                &:first-of-type {
                    display: block;
                }
            }
        }

        .filter {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 78%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
        }

        .slider-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            pointer-events: none;
            
            .slider-content_slide {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: absolute;
                pointer-events: none;
                display: none;

                .slide_inner {
                    position: absolute;
                    pointer-events: all;
                    width: 90%;
                    max-width: 800px;
                    top: 35%;
                    left: 50%;
                    transform: translateX(-50%);
                    color: $white;
                    text-align: center;
                    @include break(tablet) {
                        top: 40%;
                    }
                    .date {
                        line-height: 85%;
                        font-size: 15em;
                        @include font-title;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: $white;
                        color: rgba(251, 251, 250, 0);
                        @include break(container) {
                            font-size: 13em;
                        }
                        @include break(small-screen) {
                            font-size: 10em;
                        }
                        @include break(tablet) {
                            font-size: 9vh;
                        }
                    }
                    .txt {
                        font-size: 1.1em;
                        opacity: 0;
                        @include break(tablet) {
                            padding-top: 10px;
                            font-size: 1em;
                        }
                    }
                }

                &:first-of-type {
                    display: block;
                    .date {
                        color: rgba(251, 251, 250, 1);
                    }
                    .txt {
                        opacity: 1;
                    }
                }
                &:nth-of-type(2) {
                    display: block;
                    transform: translateX(50%);
                }
            }
        }
    }

    .slider-titre {
        flex-shrink: 0;
        width: 275px;
        color: rgba(251, 251, 250, 0.60);
        @include font-medium;
        font-size: 15px;
        text-transform: uppercase;
        @include break(small-screen) {
            width: 250px;
        }
        @include break(tablet) {
            position: absolute;
            top: $pad-med_mobile;
            left: 0;
            right: 0;
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
            z-index: 5;
            text-align: center;
        }

        &.title_mobile {
            display: none;
            @include break(tablet) {
                display: block;
            }
        }
        &.title_desktop {
            @include break(tablet) {
                display: none;
            }
        }
    }

    .slider_nav {
        position: absolute;
        bottom: 60px;
        left: 5%;
        right: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 4;

        .slider-bullets {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 1;
            padding-left: 5%;
            padding-right: 5%;
            align-items: center;
            justify-content: center;
            @include break(tablet) {
                padding-left: 44px;
            }
            
            .bullet {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin: 0 8px;
                background-color: rgba(251, 251, 250, 0.50);
                transition: all 0.4s;
                // cursor: pointer;
                &.active {
                    background-color: rgba(251, 251, 250, 1);
                    width: 12px;
                    height: 12px;
                }

                // &:hover {
                //     width: 12px;
                //     height: 12px;
                // }
            }
        }

        .slider-arrows {
            width:275px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include break(small-screen) {
                width: 250px;
            }
            @include break(tablet) {
                width:44px;
            }
            
            .arrow {
                transition: all 0.4s;
                cursor: pointer;
                &:hover {
                    transform: scale(1.25);
                }
            }

            .arrow_left {
                margin-right: 22px;
                @include break(tablet) {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .not-active {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}