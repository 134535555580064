// MODULE - 01 - HEADER

.module-01-header {
	height: 95vh;
	position: relative;
	@include break(mobile) {
		height: 70vh;
	}

	.bg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		overflow: hidden;

		picture,
		video,
		img {
			object-fit: cover;
			@include absolute-center(center);
			width: 100%;
			height: 100%;
		}

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 300%;
			height: 56.25vw;
			min-width: 177.78vh;
			min-height: 100vh;

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
				width: 200%;
			}

			@include break(tablet) {
				width: 300%;
                height: 300%;
				min-width: 400vh;
			}
		}
	}

	.filter {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		background: rgba(36, 36, 33, 1);
		mix-blend-mode: multiply;
	}

	.content {
		padding-top: calc(#{$header-h} + 12%);
		@include break(mobile) {
			padding-top: calc(#{$header-h_mobile} + 30%);
		}
		// header + 12%;
		position: relative;
		z-index: 3;
		color: $white;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		h1 {
			padding-bottom: $pad-small;
		}
	}

	&.type-home {
		height: 100vh;
		min-height: 820px;
		background-color: $off-white;
		@include break(tablet) {
			min-height: 0;
			height: auto;
		}
		.bg,
		.filter {
			@include break(tablet) {
				height: 40%;
				padding-bottom: 60px;
			}

			&.iframe {
				@include break(tablet) {
					height: 100%;
				}
			}
		}
		.content {
			padding-top: calc(#{$header-h} + 8%);
			@include break(mobile) {
				padding-top: calc(#{$header-h_mobile} + 30%);
			}
		}

		.form_container {
			position: absolute;
			bottom: 11%;
			left: 50%;
			transform: translateX(-50%);
			z-index: 4;
			max-width: 1200px;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			@include break(tablet) {
				position: relative;
				bottom: auto;
				left: auto;
				transform: none;
				width: 90%;
				margin-top: 40%;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 5%;
			}
			form {
				width: 100%;
				display: flex;
				@include break(tablet) {
					flex-direction: column;
				}
			}

			.field_container {
				padding: 32px;
				width: calc(100% - 112px);
				flex-shrink: 1;
				background-color: $off-white;
				border-radius: 12px;
				position: relative;
				z-index: 2;
				@include break(tablet) {
					width: 100%;
					background-color: $mole;
					border-radius: 7px;
				}

				.form_row {
					display: flex;
					@include break(tablet) {
						flex-direction: column;
					}
				}

				.input_wrap {
					border-radius: 4px;
					border: 1px solid $dark-mole;
					background-color: $white;
					display: flex;
					align-items: center;
					position: relative;
					height: 50px;
					margin-right: 12px;
					margin-bottom: 12px;
					max-width: 24%;
					flex-grow: 1;
					overflow: hidden;
					&:last-of-type {
						margin-right: 0;
					}
					@include break(tablet) {
						width: 100%;
						max-width: 100%;
					}

					label {
						position: absolute;
						display: flex;
						align-items: center;
						flex-shrink: 0;
						top: 0;
						left: 0;
						bottom: 0;
						padding: 12px 12px 12px 15px;
						color: $dark-grey;
						pointer-events: none;
						svg {
							margin-right: 6px;
							margin-top: -3px;
						}
					}

					select,
					input {
						height: 50px;
						flex-shrink: 1;
						appearance: none;
						border: none;
						background-color: transparent;
						outline: none;
						padding-left: 50px;
						padding-right: 20px;
						display: flex;
						color: $dark;
					}

					optgroup {
						font-style: normal !important;
					}

					#dep {
						padding-left: 70px;
					}
					#dest {
						padding-left: 90px;
					}

					input[type="date"]::-webkit-calendar-picker-indicator {
						background: transparent;
						bottom: 0;
						color: transparent;
						cursor: pointer;
						height: auto;
						left: 0;
						position: absolute;
						right: 0;
						top: 0;
						width: auto;
					}

					&.hidden {
						display: none;
					}
				}

				.checkbox_wrap {
					display: flex;
					align-items: center;
					margin-right: 12px;
					@include break(tablet) {
						margin-bottom: 12px;
					}
					label {
						margin-right: 8px;
					}
					input {
						width: 16px;
						height: 16px;
						appearance: none;
						background-color: $white;
						border-radius: 2px;
						border: 1px solid $dark-mole;
						padding: 0;
						margin: 0;
						margin-right: 6px;
						transition: all 0.4s;
						position: relative;

						&:before {
							content: " ";
							width: 8px;
							height: 8px;
							background-color: $orange;
							position: absolute;
							top: 3px;
							left: 3px;
							border-radius: 1px;
							opacity: 0;
							transform: scale(0);
							transition: all 0.4s;
						}

						&:focus {
							border-color: $orange;
						}

						&:checked {
							border-color: $orange;
							&:before {
								opacity: 1;
								transform: scale(1);
							}
						}
					}
				}
			}

			.submit_container {
				flex-shrink: 0;
				background-color: $orange;
				position: relative;
				margin-left: -32px;
				position: relative;
				z-index: 1;
				width: 144px;
				border-radius: 12px;
				position: relative;
				overflow: hidden;
				@include break(tablet) {
					width: 100%;
					margin-left: 0;
					height: 115px;
					margin-top: -50px;
					border-radius: 7px;
				}

				svg {
					@include absolute-center(center);
					pointer-events: none;
					z-index: 4;
					left: calc(50% + 16px);
					transition: all 0.4s;
					@include break(tablet) {
						width: 30px;
						left: 50%;
						top: calc(50% + 25px);
					}
				}
				input {
					cursor: pointer;
					width: 100%;
					height: 100%;
					background-color: transparent;
					font-size: 1px;
					color: $orange;
					appearance: none;
					border: none;
					outline: none;
				}

				&:hover {
					svg {
						transform: translate(-50%, -50%) scale(1.1);
					}
				}
			}
		}
	}

	&.type-bg-img {
		margin-bottom: -60px;

		@include break(tablet) {
			margin-bottom: 0;
		}

		.bottom {
			height: 65px;
			background-color: $off-white;
			border-radius: 60px 60px 0 0;
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			z-index: 4;
			@include break(mobile) {
				height: 40px;
				border-radius: 20px 20px 0 0;
			}
		}
	}

	&.type-slider {
		height: auto;
		background-color: $off-white;
		color: $dark;

		.content {
			padding-top: calc(#{$pad-xlarge} + #{$header-h});
			padding-bottom: $pad-xlarge;
			color: $dark;
			@include break(mobile) {
				padding-top: calc(#{$pad-xlarge_mobile} + #{$header-h_mobile});
				padding-bottom: $pad-xlarge_mobile;
			}
		}

		.slider-hero {
			position: relative;
			.slider-hero_container {
				pointer-events: none;
				overflow: hidden;
				width: 100%;
				height: 42vw;
				position: relative;

				.slide {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 100%;
					width: 70%;
					overflow: hidden;
					border-radius: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					display: none;
					opacity: 0.4;

					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}

					&:first-of-type {
						display: block;
						opacity: 1;
					}
					&:nth-of-type(2) {
						display: block;
						transform: translate(40%, -50%) scale(0.77);
					}
					&:last-of-type {
						display: block;
						transform: translate(-140%, -50%) scale(0.77);
					}
				}
			}

			.slider-hero_nav {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: flex;
				justify-content: space-between;
				width: 85%;

				div {
					cursor: pointer;
					svg {
						circle,
						path {
							transition: all 0.4s;
						}
					}

					&:hover {
						circle {
							fill: $orange;
						}
						path {
							fill: $white;
						}
					}
				}
				@include break(mobile) {
					display: none;
				}
			}
		}
	}
}

.post-type-archive-pages_business,
.single-pages_business {
	.slider-hero_nav div:hover circle {
		fill: $business !important;
	}
}

.post-type-archive-pages_events,
.single-pages_events,
.post-type-archive-realisations,
.single-realisations {
	.slider-hero_nav div:hover circle {
		fill: $event !important;
	}
}
