
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Light.eot');
  src: url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Light.woff2') format('woff2'),
      url('../fonts/Gotham-Light.woff') format('woff'),
      url('../fonts/Gotham-Light.ttf') format('truetype'),
      url('../fonts/Gotham-Light.svg#Gotham-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('../fonts/Gotham-Book.eot');
  src: url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Book.woff2') format('woff2'),
      url('../fonts/Gotham-Book.woff') format('woff'),
      url('../fonts/Gotham-Book.ttf') format('truetype'),
      url('../fonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Medium.eot');
  src: url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Medium.woff2') format('woff2'),
      url('../fonts/Gotham-Medium.woff') format('woff'),
      url('../fonts/Gotham-Medium.ttf') format('truetype'),
      url('../fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'OptimaLTPro-Medium';
  src: url('../fonts/OptimaLTPro-Medium.eot');
  src: url('../fonts/OptimaLTPro-Medium.eot') format('embedded-opentype'),
      url('../fonts/OptimaLTPro-Medium.woff2') format('woff2'),
      url('../fonts/OptimaLTPro-Medium.woff') format('woff'),
      url('../fonts/OptimaLTPro-Medium.ttf') format('truetype'),
      url('../fonts/OptimaLTPro-Medium.svg#OptimaLTPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



//FONT

@mixin font-regular {
  font-family: 'Gotham Book', sans-serif;
  font-weight: 400;
}
@mixin font-medium {
  font-family: 'Gotham', sans-serif;
  font-weight: 500;
}
@mixin font-light {
  font-family: 'Gotham', sans-serif;
  font-weight: 300;
}
@mixin font-title {
  font-family: 'OptimaLTPro-Medium', serif;
  font-weight: 500;
}