.module-26-promo {
    position: relative;
    overflow: hidden;

    .top_nav {
        display: flex;
        padding-bottom: 80px;
        align-items: flex-end;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
            align-items: flex-start;
        }

        .title {
            width: 60%;
            @include break(tablet) {
                width: 100%;
            }
        }
    }
    .nav {
        display: flex;
        align-items: center;
        &.desktop {
            @include break(mobile) {
                display: none!important;
            }
        }
        &.mobile {
            display: none;
            @include break(mobile) {
                display: flex;
                justify-content: space-between;
                padding-top: 30px;
            }
        }
        .arrow {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            position: relative;
            transition: all 0.4s;
            margin-left: 14px;
            cursor: pointer;
            svg {
                transition: all 0.4s;
            }
            @include break(tablet) {
                margin-left: 0px;
            }
            &:hover {
                transform: scale(1.1);
                svg {
                    transform: scale(0.8);
                }
            }

            &.not_active {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    .bg {
        @include bg-img;
        z-index: 0;
        top: 56%;
        left: 54%;    
    }

    >div {
        position: relative;
        z-index: 2;
    }

    .promo_card {
        border-radius: 12px;
        overflow: hidden;
        background-color: $white;
        width: 316px;
        min-height: 464px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        position: relative;
        transition: all 0.4s;
        border: 1px solid rgba($color: $dark-mole, $alpha: 0);
        flex-shrink: 0;
        display: flex;
        flex-direction: column;

        .img_container {
            position: relative;
            overflow: hidden;
            padding-bottom: 101%;

            img {
                @include bg-img;
                transition: all 0.4s;
            }
        }

        .content {
            height: 100%;
            flex-shrink: 1;
            padding: 16px 20px 18px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .links {
                display: flex;
                align-items: center;
            }

            svg {
                display: block;
                margin-right: 10px;
                transition: all 0.4s;
            }
        }

        &-ghost {
            padding: 0;
            height: 0;
            min-height: 0;
            opacity: 0;
            background-color: transparent;
        }
    }

    a.promo_card:hover {
        img {
            transform: translate(-50%,-50%) scale(1.03);
        }
        svg {
            transform: scale(1.03);
        }
    }

    .promo_grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: -10px;
        margin-right: -10px;
        @include break(mobile) {
            margin-left: 0px;
            margin-right: 0px;
            flex-direction: column;
            align-items: center;
        }

        .promo_card { 
            @include break(mobile) {
                flex-direction: row;
                align-items: center;
                height: auto;
                min-height: 160px;
                width: 100%;
                max-width: 400px;
                min-width: 0;
                margin-left:0;
                margin-right: 0;

                .img_container {
                    width: 50%;
                    padding-bottom: 50%;
                }

                .content {
                    width: 50%;
                    justify-content: center;
                    h5 {
                        font-size: 1.15em;
                    }
                    .links {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .promo_slider {
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        &::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        min-height: -webkit-fill-available;
        padding-left: calc((100vw - 1325px) / 2);
        padding-right: calc((100vw - 1325px) / 2);
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        @include break(container){
            padding-left: 5%;
        }

        .promo_card {
            margin-bottom: 0;
            scroll-snap-align: start;
            scroll-margin: calc((100vw - 1325px) / 2);
            @include break(container){
                scroll-margin: 5vw;
            }
            @include break(mobile) {
                margin-right: 5%;
            }
        }
    }


    .promo_disney_container {
        position: relative;

        .promo_disney {
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            min-height: -webkit-fill-available;
            padding-left: calc((100vw - 1325px) / 2);
            padding-right: calc((100vw - 1325px) / 2);
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            @include break(container){
                padding-left: 5%;
            }

            .promo-disney_card {
                scroll-snap-align: start;
                scroll-margin: calc((100vw - 1325px) / 2);
                width: 41%;
                padding-bottom: 29.5%;
                position: relative;
                overflow: hidden;
                flex-shrink: 0;
                border-radius: 12px;
                margin-left: 10px;
                margin-right: 10px;
                img {
                    @include bg-img;
                }

                @include break(container){
                    scroll-margin: 5vw;
                }
                @include break(mobile) {
                    width: 92%;
                    margin-right: 2.5%;
                }
            }

            .promo_disney_box {
                scroll-snap-align: start;
                scroll-margin: calc((100vw - 1325px) / 2);
                width: 315px;
                position: relative;
                overflow: hidden;
                flex-shrink: 0;
                border-radius: 12px;
                margin-left: 10px;
                margin-right: 10px;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: $orange;
                padding: 40px;
                text-align: center;
                color: $white;
                line-height: 120%;

                svg {
                    padding-bottom: 12px;
                    transition: all 0.4s;
                }

                &:hover {
                    svg {
                        transform: scale(1.4);
                    }
                }
            }
        }

    }
}