.template-actualites {

    .hero-actu {
        height: 490px;
        background-color: $orange;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: $white;
        padding-top: 125px;
        padding-bottom: 55px;
        @include break(tablet) {
            padding-bottom: 60px;
        }
    }

    .filter-wrap {
        padding-top: 40px;
        padding-bottom: 65px;
        .container {
            display: flex;
            overflow-x: scroll;
            .filter {
                font-size: 20px;
                @include font-medium;
                margin-right: 28px;
                position: relative;
                transition: all 0.4s;
                padding-top: 4px;
                padding-bottom: 4px;
                cursor: pointer;
                &:after {
                    content: " ";
                    background-color: $orange;
                    height: 0;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    display: block;
                    position: absolute;
                    transition: all 0.4s;
                }
                &.active {
                    pointer-events: none;
                    color: $orange;
                    &:after {
                        height: 4px;
                    }
                }

                &:hover {
                    &:after {
                        height: 4px;
                    }
                }
            }
        }
    }

    .collapse_container {
        .collapse_title .info {
            width: 100%;
            padding-right: 5%;
            flex-shrink: 1;
            .date {
                font-size: 15px;
                color: $orange;
                padding-bottom: 7px;
            }
            .tag_wrap {
                margin-top: 5px;
                display: flex;
                .tag {
                    border-radius: 6px;
                    background-color: $off-white;
                    color: $orange;
                    padding: 5px 8px 3px 8px;
                    font-size: 13px;
                    @include font-medium;
                    margin-right: 8px;
                }
            }
        }
        .txt a {
            text-decoration: underline;
            color: $orange;
        }
        .btn {
            margin-top: 20px;
        }
    }

    .page-content {
        overflow: hidden;
        position: relative;
        z-index: 10;
        margin-top: -55px;
        padding-top: 40px;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
        background-color: $off-white;
        padding-bottom: 80px;
    }

}