.blog_nav {
	background-color: $blue;
	color: $white;
	position: sticky;
	top: 136px;
	left: 0;
	right: 0;
	transition: all 0.5s $ease-out-quad;
	z-index: 998;
	align-items: center;
	padding: 16px 10%;
	font-size: 0.875em;
	@include break(small-screen) {
		padding: 16px 5%;
		font-size: 0.875em;
	}
	@include break(tablet) {
		position: fixed;
		top: 122px;
		font-size: 1em;
	}

	.nav-mobile {
		display: none;
		@include break(tablet) {
			display: flex;
			align-items: center;
		}

		.icon {
			margin-right: 14px;
			position: relative;
			width: 25px;
			height: 25px;
			svg {
				transition: opacity 0.4s;
			}
			.close {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
			}
		}
	}

	&.open .nav-mobile {
		.close {
			opacity: 1;
		}
		.open {
			opacity: 0;
		}
	}

	.content_nav {
		display: flex;
		position: relative;
		@include break(tablet) {
			display: none;
			margin-top: 18px;
			margin-left: -6%;
			margin-right: -6%;
			border-top: 2px solid rgba(251, 251, 250, 0.2);
			padding: 32px 6% 16px 6%;
			height: calc(100vh - 180px);
			overflow-y: scroll;
			overflow-x: hidden;
			transition: height 0.5s;
		}
	}

	.nav {
		display: flex;
		align-items: center;
		max-width: 100%;
		flex-shrink: 1;
		@include break(tablet) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.cat {
		margin-right: 35px;
		display: flex;
		align-items: center;
		@include break(small-screen) {
			margin-right: 20px;
		}
		@include break(tablet) {
			margin-bottom: 35px;
			margin-right: 0px;
			flex-wrap: wrap;
			width: 100%;
			justify-content: space-between;
		}

		a {
			z-index: 2;
			@include font-medium;
			position: relative;
			&:after {
				content: " ";
				width: 100%;
				height: 0px;
				position: absolute;
				bottom: -8px;
				left: 0;
				right: 0;
				background-color: $orange;
				transition: all 0.3s;
			}

			&:hover:after {
				height: 3px;
			}
		}

		.arrow_btn {
			cursor: pointer;
			margin-left: 10px;
			z-index: 2;
			position: relative;
			@include break(tablet) {
				margin-left: 30px;
			}
			svg {
				transition: all 0.4s;
			}

			&.open svg {
				transform: rotate(180deg);
			}
		}

		.sub-menu {
			position: absolute;
			left: -10vw;
			right: -10vw;
			top: 85%;
			background-color: $blue;
			min-width: 215px;
			opacity: 0;
			transform: translateY(-20px);
			transition: all 0.4s;
			z-index: 1;
			pointer-events: none;
			padding: 48px 10vw 18px 10vw;
			overflow: hidden;
			@include break(small-screen) {
				padding: 34px 5vw;
				left: -5vw;
				right: -5vw;
			}
			@include break(tablet) {
				position: initial;
				padding: 0;
				width: 100%;
				display: none;
			}

			.line {
				display: block;
				position: absolute;
				top: 20px;
				background-color: rgba(251, 251, 250, 0.2);
				height: 1px;
				left: 0;
				width: 100%;
				transition: all 0.2s;
				opacity: 0;
				@include break(tablet) {
					display: none;
				}
			}

			.sub-menu_container {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				height: 143px;
				width: fit-content;
				@include break(tablet) {
					width: 100%;
					height: auto;
				}

				.sub-cat {
					width: 250px;
					padding-right: 50px;
					@include break(tablet) {
						width: 100%;
						padding-right: 0px;
						margin-top: 20px;
					}
				}
			}
		}

		&:hover {
			@include break(desktop-only) {
				svg {
					transform: rotate(180deg);
				}
				.sub-menu {
					pointer-events: all;
					opacity: 1;
					transform: translateY(0px);
					.line {
						transition: all 0.6s 0.2s;
						opacity: 1;
					}

					.sub-cat {
						margin-bottom: 12px;
						a {
							font-size: 14px;
							transition: all 0.2s;
							&:hover:after {
								height: 2px;
							}
						}
					}
				}
			}
		}

		&.active > a {
			color: $orange;
			&:after {
				height: 3px;
			}
		}
	}

	.search {
		flex-shrink: 0;
		margin-left: 15px;
		border-left: 2px solid rgba(251, 251, 250, 0.2);
		color: rgba(251, 251, 250, 0.2);
		padding-left: 50px;
		position: relative;
		z-index: 3;

		@include break(tablet) {
			margin-left: 0px;
			border-top: 2px solid rgba(251, 251, 250, 0.2);
			border-left: none;
			padding-left: 0;
			padding-top: 20px;
		}
		form {
			display: flex;
			align-items: center;
			input {
				width: 115px;
				border: none;
				background-color: transparent;
				color: rgba(251, 251, 250, 0.2);
				transition: all 0.4s;
				&::placeholder {
					color: rgba(251, 251, 250, 0.2);
				}

				&:focus {
					border: none;
					outline: none;
					color: rgba(251, 251, 250, 1);
					&::placeholder {
						color: rgba(251, 251, 250, 1);
					}
				}
			}
			button {
				appearance: none;
				border: none;
				background-color: transparent;
				opacity: 0.2;
				transition: all 0.4s;
				cursor: pointer;
				&:focus,
				&:hover {
					border: none;
					outline: none;
					opacity: 1;
				}
			}
		}
	}
}

.template-blogue {
	padding-top: 136px;
	position: relative;

	.principal {
		padding-top: 80px;
		display: flex;
		padding-bottom: 140px;
		@include break(tablet) {
			flex-direction: column;
			padding-bottom: 80px;
		}

		strong {
			color: $orange;
		}

		.content {
			padding-left: calc((100vw - #{$container}) / 2);
			padding-right: 5%;
			@include break(container) {
				padding-left: 5%;
			}
			.top_info {
				h1 {
					font-size: 44px;
					margin-bottom: 8px;

					@include break(tablet) {
						font-size: 32px;
					}
				}
				.info {
					display: flex;
					align-items: center;
					color: $dark-grey;
					margin-bottom: 20px;

					.date {
						display: flex;
						align-items: center;
						font-size: 15px;
						padding-right: 12px;
						svg {
							margin-bottom: 2px;
							margin-right: 6px;
							path {
								fill: $dark-grey;
							}
						}
					}
					.time {
						display: flex;
						align-items: center;
						font-size: 15px;
						padding-left: 12px;
						svg {
							margin-bottom: 2px;
							margin-right: 6px;
							path {
								fill: $dark-grey;
							}
						}
					}
				}
			}
			.header_img {
				width: 100%;
				border-radius: 12px;
				position: relative;
				overflow: hidden;
				margin-bottom: 50px;
				img {
					width: 100%;
				}
				.tag_wrap {
					position: absolute;
					top: 12px;
					right: 12px;
					display: flex;
					.tag {
						border-radius: 6px;
						background-color: $orange;
						padding: 5px 8px 3px 8px;
						margin-left: 8px;
						font-size: 13px;
						@include font-medium;
						color: $white;
					}
				}
			}

			.header_desc {
				h1,
				h2,
				h3,
				h4 {
					@include font-medium;

					&:first-of-type {
						margin-top: 32px;
					}
				}

				h2,
				h3 {
					color: $orange;

					img {
						margin-top: 25px;
						width: 100%;
						height: 100%;
						max-height: 500px;
						border-radius: 20px;
						overflow: hidden;
						margin-bottom: 50px;
						object-fit: cover;

						@include break(tablet) {
							max-height: 200px;
						}
					}
				}

				h2 {
					font-size: 42px;
				}

				h3 {
					font-size: 32px;
					margin-top: 50px;
					margin-bottom: 12px;

					@include break(tablet) {
						margin-top: 40px;
					}
				}

				b {
					@include font-medium;
				}

				p {
					margin: 12px 0;
					img {
						margin-top: 25px;
						width: 100%;
						height: 100%;
						max-height: 500px;
						border-radius: 20px;
						overflow: hidden;
						margin-bottom: 50px;
						object-fit: cover;

						@include break(tablet) {
							max-height: 200px;
						}
					}
				}
			}

			.page-content {
				.module_titre {
					margin-top: 50px;
					.day {
						display: inline-block;
						color: $orange;
					}
					.title-h6 {
						display: inline-block;
					}
				}

				.module_texte {
					padding-top: 25px;
				}

				.module_lien {
					padding-top: 25px;

					.btn svg path {
						fill: $white;
					}
				}

				.module_img {
					margin-top: 25px;
					width: 100%;
					position: relative;
					padding-bottom: 56%;
					border-radius: 20px;
					overflow: hidden;
					margin-bottom: 50px;
					img {
						@include bg-img;
					}
				}

				.module_moz {
					margin-top: 25px;
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: flex-start;

					.img_left {
						width: calc(50% - 6px);
						position: relative;
						padding-bottom: 47%;
						border-radius: 20px;
						overflow: hidden;
						margin-bottom: 50px;
					}
					.img_right {
						width: calc(50% - 6px);
						display: flex;
						flex-direction: column;

						.img_top {
							position: relative;
							border-radius: 20px;
							padding-bottom: 46.25%;
							overflow: hidden;
						}
						.img_bottom {
							position: relative;
							border-radius: 20px;
							overflow: hidden;
							padding-bottom: 46.25%;
							margin-top: 12px;
						}
					}

					img {
						@include bg-img;
					}
				}
			}
		}

		.sidebar {
			margin-top: 40px;
			flex-shrink: 0;
			width: 445px;
			background-color: $mole;
			border-radius: 12px 0px 0px 12px;
			position: relative;
			padding: 85px 48px;
			@include break(small-screen) {
				padding: 65px 32px;
				width: 350px;
			}
			@include break(tablet) {
				width: 100%;
				border-radius: 0;
				padding: 50px 5%;
			}

			.sidebar_content {
				transition: top 0.5s;
				position: sticky;
				top: 280px;
				left: 0;
				@include break(tablet) {
					position: initial;
					top: auto;
				}

				h5 {
					padding-bottom: 20px;
				}

				.top_article {
					.card_article-small {
						display: flex;
						padding-bottom: 20px;
						margin-bottom: 20px;
						border-bottom: 1px solid #eeede2;
						.img_thumb {
							overflow: hidden;
							flex-shrink: 0;
							width: 125px;
							height: 83px;
							border-radius: 12px;
							position: relative;
							img {
								@include bg-img;
							}
							margin-right: 18px;
							@include break(small-screen) {
								width: 102px;
								height: 70px;
							}
							@include break(tablet) {
								width: 125px;
								height: 83px;
							}
						}
						.content_card {
							width: 100%;
							flex-shrink: 1;
							.title {
								font-size: 15px;
								@include font-medium;
							}
							.date {
								display: flex;
								align-items: center;
								color: $dark-grey;
								font-size: 13px;
								margin-top: 5px;

								svg {
									margin-bottom: 2px;
									margin-right: 6px;
									path {
										fill: $dark-grey;
									}
								}
							}
						}
					}
				}

				.share_content {
					padding-top: 40px;
					.share {
						display: flex;
						align-items: center;
						a {
							margin-right: 30px;
							&:hover {
								transform: scale(1.07);
							}
						}
					}
				}

				.newsletter_content {
					padding-top: 40px;

					p {
						margin-bottom: 12px;
					}

					label {
						display: none;
					}
					.field {
						position: relative;
						.mc-field-group input {
							width: 100%;
							height: 65px;
							background-color: $white;
							border-radius: 8px;
							border: 2px solid $white;
							transition: all 0.4s;
							padding: 20px 74px 20px 20px;
						}
					}
					.button {
						background-color: $orange;
						width: 58px;
						height: 58px;
						position: absolute;
						top: 4px;
						right: 4px;
						bottom: 4px;
						left: auto;
						border-radius: 5px;
						outline: none;
						border: none;
						color: rgba($orange, 0);
						font-size: 1px;
						z-index: 333;
						cursor: pointer;
						background-image: url("../img/arrow.svg");
						background-position: center;
						background-size: 24px;
						background-repeat: no-repeat;
						transition: all 0.4s;
						&:hover {
							background-size: 28px;
						}
					}
				}
			}
		}
	}

	.module-15-cta_bg-img {
		margin-top: 120px;
		@include break(tablet) {
			margin-top: 80px;
		}
	}
}

body.up {
	.blog_nav {
		top: 0px;
		.content_nav {
			@include break(tablet) {
				height: calc(100vh - 55px);
			}
		}
	}
	.template-blogue .principal .sidebar .sidebar_content {
		top: 120px;
	}
}
