.module-20-map {

    .container {
        display: flex;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
        }
    }

    .left {
        width: 32%;
        @include break(tablet) {
            width: 100%;
        }

        .txt {
            padding-top: 14px;
            padding-bottom: 40px;
        }
    }
    .right {
        width: 65%;
        position: relative;
        @include break(tablet) {
            width: 100%;
            margin-top: 60px;
        }
        img {
           width: 100%;
           height: auto;
        }
    }
}