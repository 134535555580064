// Module – 03 – Introduction
 .module-03-intro {
    &.flex-block_first {
        padding-top: 200px;
        @include break(tablet) {
            padding-top: 160px;
        }
    }

    // TYPE: AVEC IMAGE
    &.type_img {

        .title_container {
            position: relative;
            padding-right: 5%;

            .title-h1 {
                color: $orange;
                position: relative;
                z-index: 2;
            }

            .img {
                position: absolute;
                width: 205px;
                height: 205px;
                z-index: 1;
                border-radius: 12px;
                overflow: hidden;
                @include break(tablet) {
                    position: relative;
                    width: 85px;
                    height: 85px;
                    right: auto!important;
                    left: auto!important;
                    bottom: auto!important;
                    border-radius: 6px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    @include absolute-center(center);
                }

                &.img1 {
                    transform: rotate(-4deg);
                    right: 10%;
                    bottom: -30px;
                    @include break(tablet) {
                        margin-left: auto;
                        margin-right: -10px;
                        margin-top: -10px;
                    }
                }
                &.img2 {
                    transform: rotate(-15deg);
                    left: 5%;
                    bottom: -145px;
                    @include break(tablet) {
                        margin-top: -85px;
                        margin-left: 10px;
                    }
                }
                &.img3 {
                    transform: rotate(12deg);
                    left: 11%;
                    bottom: -300px;
                    @include break(tablet) {
                        margin-top: -25px;
                        margin-left: 40px;
                    }
                }
            }
        }

        .txt_container {
            display: flex;
            justify-content: flex-end;
            padding-top: 100px;
            @include break(tablet) {
                padding-top: 20px;
                justify-content: flex-start;
            }

            .txt {
                width: 450px;
                padding-left: 20px;
                border-left: 4px solid $orange;
                @include break(tablet) {
                    width: 100%;
                }
    
            }
        }

    }


    // TYPE: 2 Colonnes (Image | Texte)
    &.type_2col {

        .content-2col {
            padding-left: 10%;
            padding-right: 10%;
            align-items: center;
            @include break(tablet) {
                flex-direction: column;
                padding-left: 5%;
                padding-right: 5%;
            }
            @include break(mobile) {
                padding-left: 0%;
                padding-right: 0%;
            }

            .img_container {
                width: 30%;
                @include break(tablet) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-bottom: $pad-med_mobile;
                }
                img {
                    width: 100%;
                    @include break(tablet) {
                        width: auto;
                        max-width: 100%;
                    }
                }
            }
            .txt_container {
                width: 60%;
                padding-left: 20px;
                border-left: 4px solid $orange;
                @include break(tablet) {
                    width: 100%;
                }
            }
        }
    }

     // TYPE: Boîte
     &.type_box {
        .content-2col {
            width: 90%;
            max-width: 875px;
            margin-left: auto;
            margin-right: auto;
            padding: 50px;
            align-items: center;
            border-radius: 12px;
            background-color: $mole;
            @include break(tablet) {
                flex-direction: column;
                padding: 30px;
            }
            @include break(mobile) {
                width: 100%;
            }

            .img_container {
                width: 30%;
                @include break(tablet) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-bottom: $pad-med_mobile;
                }
                img {
                    width: 100%;
                    @include break(tablet) {
                        width: auto;
                        max-width: 100%;
                    }
                }
            }
            .txt_container {
                width: 60%;
                @include break(tablet) {
                    width: 100%;
                }
            }
        }

        &.bg_mole {
            .content-2col {
                background-color: $dark-mole;
            } 
        }
     }
}


.post-type-archive-pages_business, .single-pages_business {

    .module-03-intro.type_img .title-h1 {
        color: $business!important;
    }

    .module-03-intro.type_img .txt_container .txt, .module-03-intro.type_2col .content-2col .txt_container {
        border-left: 4px solid $business;
    }

    .module-22-slider_key .slider_nav .arrow svg path {
        fill: $business;
    }
}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-03-intro.type_img .title-h1 {
        color: $event!important;
    }

    .module-03-intro.type_img .txt_container .txt, .module-03-intro.type_2col .content-2col .txt_container {
        border-left: 4px solid $event;
    }

    .module-22-slider_key .slider_nav .arrow svg path {
        fill: $event;
    }
}