.module-33-infos {
    .info_nav {
        display: flex;
        justify-content: center;
        margin-bottom: 65px;
        overflow-x: scroll;
        @include break(mobile) {
            justify-content: flex-start;
            overflow-y: hidden;
            overflow-x: scroll;
            margin-bottom: 45px;
        }

        .tab-nav {
            @include font-medium;
            font-size: 1.15em;
            margin-right: 14px;
            margin-left: 14px;
            cursor: pointer;
            position: relative;
            transition: all 0.4s;

            &:hover {
                color: $orange;
            }

            &::before {
                content: " ";
                background-color: $orange;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 0px;
                transition: all 0.4s;
            }

            &.active {
                color: $orange;
                &::before {
                    height: 3px;
                }
            }
        }
    }

    .container {
        position: relative;
        height: 630px;
        @include break(mobile) {
            height: 460px;
            margin-bottom: 60px;
        }

        .arrow {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            position: relative;
            transition: all 0.4s;
            margin-left: 14px;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include break(mobile) {
                top: 100%;
                transform: translateY(0%);
            }
            svg {
                transition: all 0.4s;
            }
            @include break(tablet) {
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 35px;
            }
            &:hover {
                transform: translateY(-50%) scale(1.1);
                @include break(mobile) {
                    transform: scale(1.1);
                }
                svg {
                    transform: scale(0.8);
                }
            }
    
            &.not_active {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        .arrow_left {
            left: 15%;
            @include break(tablet) {
                order: 2;
            }
        }
        .arrow_right {
            right: 15%;
            @include break(tablet) {
                order: 3;
            }
        }


        .card_info {
            width: 455px;
            height: 630px;
            border-radius: 12px;
            overflow-x: hidden;
            overflow-y: scroll;
            border: 1px solid $mole;
            background-color: $mole;
            padding: 40px;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center;
            transform: translate(-50%,-50%);
            pointer-events: none;
            opacity: 0;
            transform-style: preserve-3d;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            @include break(mobile) {
                width: 335px;
                height: 460px;
            }

            .content {
                opacity: 0;
            }
            .title-h2 {
                color: $orange;
                padding-bottom: 5px;
                padding-top: 5px;
                flex-shrink: 0;
            }

            .txt_contenu {
                height: 100%;
                flex-shrink: 1;
                overflow-y: scroll;
                overflow-x: auto;

                .temp_container {
                    .row_title {
                        display: flex;
                        justify-content: space-between;

                        .title {
                            @include font-medium;
                            display: flex;
                            text-align: center;
                            justify-content: center;
                            align-items: center;
                            padding: 5px;
                            border: 1px solid $dark;
                            width: 35%;
                            border-right: none;
                            &:last-of-type {
                                border-right: 1px solid $dark;
                            }
                        }
                    }
                }
                .temp {
                    h6 {
                        border-left: 1px solid $dark;
                        border-right: 1px solid $dark;
                        padding: 5px;
                        text-align: center;
                        color: $orange;
                    }
                    .temp_table {
                        display: flex;
                        justify-content: space-between;

                        .cell {
                            display: flex;
                            text-align: center;
                            justify-content: center;
                            align-items: center;
                            padding: 5px;
                            border: 1px solid $dark;
                            width: 35%;
                            border-right: none;
                            &:last-of-type {
                                border-right: 1px solid $dark;
                            }
                        }
                    }
                }
            }

            &:first-of-type {
                opacity: 1;
                pointer-events: all;
                z-index: 2;
                transform: translate(-50%,-50%) rotate(0deg);
                background-color: $white;
                .content {
                    opacity: 1;
                }
            }
            &:nth-of-type(3) {
                opacity: 1;
                transform: translate(-50%,-50%) rotate(6deg);
            }
        }    
    }
}