// Module - 12 - Contenu / Icônes (valeurs)

.module-12-content_icon {

    .value_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: -60px;
        @include break(mobile){
            margin-bottom: -$pad-med_mobile;
        }

        .value {
            width: 30%;
            margin-bottom: 60px;
            @include break(tablet){
                width: 45%;
            }
            @include break(mobile){
                width: 100%;
                margin-bottom: $pad-med_mobile;
            }
            .icon {
                width: 92px;
                height: 92px;
                position: relative;
                margin-bottom: 24px;
                @include break(mobile){
                    width: 60px;
                    height: 60px;
                }

                img {
                    @include absolute-center(center);
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            h5 {
               padding-bottom: $pad-xxsmall;
            }

            &.ghost {
                margin-bottom: 0;
            }
        }
    }

}