// Module - 22 - Carrousel / Chiffres clés

.module-22-slider_key {
    position: relative;
    .slider-key_container {
        height: 560px;
        width: 100%;
        position: relative;
        overflow: hidden;
        @include break(mobile) {
            height: 445px;
        }

        .slider-key {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .slider-key_slide {
            width: 316px;
            height: 300px;
            border-radius: 20px;
            overflow: hidden;
            padding: 32px 26px;
            background-color: $powder-blue;
            justify-content: space-between;
            flex-direction: column;
            position: absolute;
            left: calc(50% - 158px);
            transform-origin: 50% 1500px;
            top: 20px;
            display: none;

            &:nth-child(3n+2) {
                background-color: $blue;
                color: $white;
            }
            &:nth-child(3n+3) {
                background-color: $orange;
                color: $white;
            }

            &:nth-child(1) {
                display: flex;
            }
            &:nth-child(2) {
                display: flex;
                transform: rotate(17deg)
            }
            &:nth-child(3) {
                display: flex;
                transform: rotate(34deg)
            }
            &:nth-last-child(1) {
                display: flex;
                transform: rotate(-17deg)
            }
            &:nth-last-child(2) {
                display: flex;
                transform: rotate(-34deg)
            }

            .top {
                .number {
                    @include font-title;
                    font-size: 5em;
                    line-height: 100%;
                }

                .logo {
                    height: 70px;
                    width: 100%;
                    position: relative;
                    img {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: contain;
                        object-position: left;
                    }
                }
            }

            .content {
                height: 100%;
                flex-shrink: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                .txt {
                    @include font-medium;
                    font-size: 0.875em;
                }
            }
        }
    }
    .slider_nav{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 125px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        @include break(mobile) {
            padding-left: 5%;
            padding-right: 5%;
            justify-content: space-between;
        }

        .bg {
            position: absolute;
            background: linear-gradient(180deg, rgba(241, 239, 236, 0.00) 0%, #F1EFEC 100%);
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            z-index: 1;
        }
        
        .arrow {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            position: relative;
            margin-left: 7px;
            margin-right: 7px;
            transition: all 0.4s;
            cursor: pointer;
            svg {
                transition: all 0.4s;
            }

            &:hover {
                transform: scale(1.1);
                svg {
                    transform: scale(0.8);
                }
            }
        }
    }
}

.post-type-archive-pages_business, .single-pages_business {

    .module-22-slider_key .slider-key_container .slider-key_slide:nth-child(3n+3) {
        background-color: $business;
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-22-slider_key .slider-key_container .slider-key_slide:nth-child(3n+3) {
        background-color: $event;
    }

}