.template-brochures {

    .brochures {

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 100px;
        @include break(mobile) {
            justify-content: center;
        }
    
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 22%;
            min-width: 230px;
            margin-bottom: 60px;
            flex-shrink: 0;
            
            .img_container {
                width: 205px;
                height: 265px;
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                background-color: $white;
                transition: all 0.4s;

                .icon {
                    @include absolute-center(center);
                }
    
                .img {
                    @include bg-img;
                }
            }

            .title {
                font-size: 1.1em;
                @include font-medium;
                margin-top: 14px;
                width: 100%;
                text-align: center;
            }
            .subtitle {
                font-size: 13px;
                @include font-light;
                margin-top: 2px;
                width: 100%;
                text-align: center;
                font-style: italic;
            }

            &:hover {
                .img_container { 
                    transform: scale(1.02);
                }
            }
        }

        .ghost {
            width: 22%;
            min-width: 230px;
        }
    }

}
