footer {
    background-color: $dark;
    color: $white;

    .content_container {
        display: flex;
        justify-content: space-between;
        padding-top: 120px;
        padding-bottom: 100px;
        flex-wrap: wrap;
        @include break(mobile) {
            padding-top: 60px;
            padding-bottom: 48px;
            align-items: center;
            flex-direction: column;
        }

        .col1 {
            width: 270px;
            margin-right: 25px;
            text-align: center;
            @include break(mobile) {
                width: 100%;
                padding-bottom: 50px;
                margin-right: 0px;
            }

            .logo_footer {
                margin-bottom: 60px;
                display: block;
                @include break(mobile) {
                    margin-bottom: 32px;
                }
                svg, img {
                    width: 100%;
                    @include break(mobile) {
                        width: 205px;
                    }
                }
            }

            p {
                font-size: 15px;
            }

            .tel {
                font-size: 1.3em;
                @include font-medium;
                padding-top: 20px;
                display: block;
            }
        }

        .col2 {
            width: 270px;
            margin-right: 25px;
            @include break(mobile) {
                width: 100%;
                padding-bottom: 50px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-right: 0px;
            }

            .links_wrap {
                padding-top: 40px;
                @include break(mobile) {
                    padding-top: 0px;
                    width: 45%;
                }
                &:first-of-type {
                    padding-top: 0;
                }
                .col-title {
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 350;
                    line-height: 120%;
                    text-transform: uppercase;
                    padding-bottom: 28px;
                    color: $dark-grey;
                    @include font-medium;
                }

                a {
                    display: block;
                    padding-bottom: 15px;
                }
            }
        }

        .col3 {
            display: flex;
            flex-direction: column;
            width: 380px;
            flex-shrink: 1;
            @include break(mobile) {
                width: 100%;
            }

            .top {
                height: 100%;
                flex-shrink: 1;
            }

            .titre_info {
                font-size: 28px;
                padding-bottom: 32px;
                @include break(mobile) {
                    font-size: 22px;
                    padding-bottom: 20px;
                }
            }

            .newsletter {
                label {
                    display: none;
                }
                .field {
                    position: relative;
                    .mc-field-group input {
                        width: 100%;
                        height: 65px;
                        background-color: $white;
                        border-radius: 8px;
                        border: 2px solid $white;
                        transition: all 0.4s;
                        padding: 20px 74px 20px 20px;
                    }
                }
                .button {
                    background-color: $orange;
                    width: 58px;
                    height: 58px;
                    position: absolute;
                    top:4px;
                    right: 4px;
                    bottom: 4px;
                    left: auto;
                    border-radius: 5px;
                    outline: none;
                    border: none;
                    color: rgba($orange,0);
                    font-size: 1px;
                    z-index: 333;
                    cursor: pointer;
                    background-image: url('../img/arrow.svg');
                    background-position: center;
                    background-size: 24px;
                    background-repeat: no-repeat;
                    transition: all 0.4s;
                    &:hover {
                        background-size: 28px;  
                    }
                }
            }

            .social_container {
                margin-top: 65px;
                display: flex;
                @include break(mobile) {
                    width: 100%;
                    justify-content: space-between;
                }
                .social {
                    display: block;
                    margin-right: 12px;
                    transition: all 0.4s;
                    @include break(mobile) {
                        margin-right: 0px;
                    }
                    circle {
                        transition: all 0.4s;
                    }

                    &:hover {
                        transform: scale(1.05);
                        circle {
                            fill: $orange;
                            fill-opacity:1;
                        }
                    }
                }
            }

            .wpml-ls-legacy-list-horizontal {
                padding: 0;
                border: none;
                @include break(mobile) {
                    margin-top: 50px;
                }

                a {
                    padding: 0;
                    font-size: 15px;
                }
            }
        }

    }

    .legal_container {
        border-top: 1px solid $dark-grey;
        padding: 25px 0;
        .legal {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
    
            .left {
                width: fit-content;
                flex-shrink: 0;
                font-size: 12px;
                color: $dark-grey;
                display: flex;
                margin-right: 15px;
                flex-wrap: wrap;
            }
            .right {
                width: fit-content;
                flex-shrink: 0;
                font-size: 12px;
                color: $dark-grey;
                display: flex;
                flex-wrap: wrap;
                @include break(mobile) {
                    padding-top: 14px;
                }
            }
        }
    }
}