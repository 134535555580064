.module-27-form {

    .form_container {
        display: flex;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
        }

        .form_wrap {
            width: 60%;
            flex-shrink: 1;
            @include break(tablet) {
                width: 100%;
            }

            form {
                br {
                    display: none;
                }
                input,textarea {
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid $dark-mole;
                    padding: 13px 18px;
                    line-height: 1em;
                    transition: all 0.4s;
                    outline: none!important;
                    margin-bottom: 8px;
                    margin-top: 8px;

                    &:focus {
                        border: 1px solid $orange;
                    }

                    &[type=submit] {
                        background-color: $orange;
                        border-radius: 42px;
                        color: $white;
                        text-transform: uppercase;
                        transition: all 0.4s;
                        cursor: pointer;
                        &:hover {
                            background-color: $white;
                            color: $orange;
                        }
                    }
                }

                label {
                    margin-bottom: 20px;
                    display: block;
                    b {
                        @include font-medium;
                    }
                }

                .row p {
                    display: flex;
                    justify-content: space-between;
                    @include break(tablet) {
                        flex-direction: column;
                    }
                    >* {
                        width: 48.5%;
                        @include break(tablet) {
                            width: 100%;
                        }
                    }
                }

                .wpcf7-checkbox, .wpcf7-radio {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 20px;
                    margin-top: 10px;

                    .wpcf7-list-item {
                        display: flex;
                        align-items: center;
                        margin-left: 0;
                        margin-right: 18px;
                        label {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                        }
                        input {
                            width: 16px;
                            height: 16px;
                            appearance: none;
                            background-color: $white;
                            border-radius: 2px;
                            border: 1px solid $dark-mole;
                            padding: 0;
                            margin: 0;
                            margin-right: 6px;
                            transition: all 0.4s;
                            position: relative;
                            cursor: pointer;

                            &:before {
                                content: " ";
                                width: 8px;
                                height: 8px;
                                background-color: $orange;
                                position: absolute;
                                top: 3px;
                                left: 3px;
                                border-radius: 1px;
                                opacity: 0;
                                transform: scale(0);
                                transition: all 0.4s;
                            }

                            &:focus {
                                border-color: $orange;
                            }

                            &:checked {
                                border-color: $orange;
                                &:before {
                                    opacity: 1;
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                }

                .wpcf7-select {
                    width: 100%;
                    display: block;
                    margin-top: 10px;
                    margin-bottom: 30px;
                    border-radius: 4px;
                    border: 1px solid $dark-mole;
                    padding: 13px 18px;
                    position: relative;
                    border-right: 16px solid transparent
                }

                .wpcf7-radio .wpcf7-list-item {
                    input {
                        border-radius: 50%;
                        &:before {
                            border-radius: 50%;
                        }
                    }
                }

                .wpcf7-files {
                    margin-bottom: 20px;
                    small {
                        margin-top: -20px;
                        display: block;
                    }
                }

                .wpcf7-acceptance .wpcf7-list-item label {
                    display: flex;
                    align-items: flex-start;
                    line-height: 1.2em;
                    input {
                        width: auto;
                        margin-right: 20px;
                        margin-top: 5px;
                    }
                }
            }
        }

        .sidebar {
            width: 315px;
            margin-left: 10%;
            @include break(tablet) {
                width: 100%;
                margin-left: 0%;
                margin-top: 30px;
            }
        }
    }
}



.post-type-archive-pages_business, .single-pages_business {

    .module-27-form .form_wrap form {
        input,textarea {
            &:focus {
                border: 1px solid $business;
            }

            &[type=submit] {
                background-color: $business;
                &:hover {
                    background-color: $white;
                    color: $business;
                }
            }
        }

        .wpcf7-checkbox, .wpcf7-radio {

            .wpcf7-list-item {
                input {
                    &:before {
                        background-color: $business;
                    }
                    &:focus {
                        border-color: $business;
                    }
                    &:checked {
                        border-color: $business;
                    }
                }
            }
        }
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-27-form .form_wrap form {
        input,textarea {
            &:focus {
                border: 1px solid $event;
            }

            &[type=submit] {
                background-color: $event;
                &:hover {
                    background-color: $white;
                    color: $event;
                }
            }
        }

        .wpcf7-checkbox, .wpcf7-radio {

            .wpcf7-list-item {
                input {
                    &:before {
                        background-color: $event;
                    }
                    &:focus {
                        border-color: $event;
                    }
                    &:checked {
                        border-color: $event;
                    }
                }
            }
        }
    }

}
