header {
  // display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;


  // TOP
  .nav_top {
    padding-left: 5%;
    padding-right: 5%;
    background-color: $dark;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1em;
    position: relative;
    z-index: 2;
    height: 40px;
    @include break(tablet) {
      padding-left: 0;
      padding-right: 0;
      height: 38px;
    }


    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      overflow-y: hidden;
      overflow-x: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar { width: 0 !important };
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      min-height: -webkit-fill-available;
      .item {
        flex-shrink: 0;

        &:first-of-type {
          @include break(tablet) {
            padding-left: 5%;
          }
        }
        &:last-of-type {
          @include break(tablet) {
            padding-right: 5%;
          }
        }
      }
      a {
        display: block;
        position: relative;
        text-transform: uppercase;
        @include font-medium;
        padding: 11px;
        color: rgba(241, 239, 236, 0.40);
        &::before {
          content: " ";
          position: absolute;
          background-color: $orange;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0px;
          transition: all 0.4s;
        }

        &:hover, &.actif {
          color: rgba(241, 239, 236, 1);
          &::before {
            height: 4px;
          }
        }
      }

      .business {
        a::before {
          background-color: $business;
        }
      }

      .event {
        a::before {
          background-color: $event;
        }
      }

    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      @include break(tablet) {
        display: none;
      }

      .item {
        padding-left: 28px;
        a {
          color: rgba(241, 239, 236, 0.80);
          &:hover {
            color: rgba(241, 239, 236, 1);
          }
        }
      }

      .tel {
        a {
          display: block;
          background-color: $orange;
          padding: 11px 17px;
          color: $white;
          &:hover {
            color: $dark;
          }
        }
      }

      .wpml-ls-legacy-list-horizontal, .wpml-ls-link {
        padding: 0;
      }
    }
  }


// MAIN
  .nav_main {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1em;
    background-color: $off-white;
    border-bottom: 1px solid #E2DDD4;
    position: relative;
    z-index: 2;

    .left {
      padding: 20px 0 18px 0;
      @include break(mobile) {
        padding:15px 0 13px 0;
      }
      .logo svg{
        width: 265px;
        @include break(tablet) {
          width: 192px;
        }
      }
    }
    .right {
      display: flex;
      font-size: 17px;
      @include break(tablet) {
        display: none;
      }

      .item {
        display: block;
        padding-top: 9px;
        padding-bottom: 9px;
        cursor: pointer;
        margin-left: 32px;
        position: relative;

        &::before {
          content: " ";
          position: absolute;
          background-color: $orange;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0px;
          transition: all 0.3s;
        }

        &:hover::before, &.active::before, &.nav_active::before {
          height: 3px;
        }
      }
    }

    .burger {
      display: none;
      @include break(tablet) {
       display: block;
      }
      position: relative;
      width: 36px;
      height: 36px;

      .line {
        background-color: $dark;
        height: 3px;
        left: 5px;
        right: 5px;
        position: absolute;
        transition: all 0.4s;

        &.line1 {
          top: 12px;
        }
        &.line2 {
          bottom: 12px;
        }
      }

      &.open {
        .line.line1 {
          transform: translateY(5px) rotate(45deg);
        }
        .line.line2 {
          transform: translateY(-5px) rotate(-45deg);
        }
      }
    }
  }

  .sub {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    min-height: 535px;
    background-color: $off-white;
    overflow: hidden;
    display: none;
    padding: 40px 2.5%;
    @include break(tablet) {
      top: 100%;
      height: calc(100vh - 110px);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 20px;
      flex-direction: column;
      padding-bottom: 160px;
    }

    .back {
      display: none;
      @include break(tablet){
        display: flex;
        align-items: center;
        font-size: 13px;
        padding-bottom: 15px;
        svg {
          transform: rotate(180deg);
          margin-right: 10px;
          margin-bottom: 2px;
        }
      }
    }

    .col-img {
      margin-right: 5%;
      flex-shrink: 0;
      @include break(tablet) {
        margin-right: 0;
        margin-bottom: 25px;
      }
      .img {
        position: relative;
        width: 414px;
        height: 306px;
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        @include break(tablet) {
          width: 100%;
          height: auto;
          padding-bottom: 65.5%;
        }
        .filter {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background: rgba(36, 36, 33, 0.30);
          mix-blend-mode: multiply;
          z-index: 2;
        }
        img {
          transition: all 0.6s;
          @include bg-img;
          z-index: 1;
        }
        .content {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 3;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: $white;
          padding: 40px;

          .btn {
            display: inline-flex;
            align-items: center;
            padding: 10px 20px 8px 20px;
            border-radius: 42px;
            background-color: $white;
            color: $orange;
            line-height: 100%;
            font-size: 14px;
            text-transform: uppercase;
            @include font-medium;
            margin-top: 25px;
            transition: all 0.4s;
            svg {
              margin-left: 12px;
              path {
                transition: all 0.4s;
              }
            }
          }
        }
      }

      a.img:hover {
          img {
            transform: scale(1.03) translate(-48.5%, -48.5%);
          }
          .btn {
            background-color: $orange;
            color: $white;
            svg path {
              fill: $white;
            }
          }
      }
    }

    .col-content {
      display: flex;
      width: 70%;
      flex-shrink: 1;
      @include break(tablet) {
        flex-direction: column;
        width: 100%;
      }

      .col {
        width: 31.5%;
        @include break(tablet) {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 30px;
          justify-content: space-between;
        }

        .col-title {
          padding: 7px;
          font-size: 13px;
          margin-bottom: 20px;
          @include break(tablet) {
            padding: 0px;
            width: 100%;
            margin-bottom: 10px;
          }
        }

        .link-img {
          display: flex;
          align-items: center;
          font-size: 16px;
          @include font-medium;
          border-radius: 12px;
          background-color: rgba($mole, 0);
          padding: 7px;
          transition: all 0.4s;
          margin-bottom: 10px;
          @include break(tablet) {
            width: 48%;
            padding: 0;
            font-size: 14px;
            line-height: 1.1em;
          }

          .img {
            width: 52px;
            height: 52px;
            flex-shrink: 0;
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            margin-right: 16px;

            img {
              @include bg-img;
            }
          }

          &:hover {
            background-color: rgba($mole, 1);
          }
        }

        .link-arrow {
          display: flex;
          align-items: center;
          color: $orange;
          font-size: 14px;
          text-transform: uppercase;
          @include font-medium;
          margin-right: 7px;
          margin-left: 7px;
          margin-top: 30px;
          @include break(tablet) {
            width: 100%;
            margin-right: 0px;
            margin-left: 0px;
          }
          svg {
            margin-left: 12px;
            margin-bottom: 2px;
            transition: all 0.4s;
          }

          &:hover svg {
            margin-left: 18px;
          }
        }

      }

    }
  }


  // MOBILE

  .nav-mobile {
    display: none;
    background-color: #F1EFEC;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding-top: 120px;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;

    .nav-mobile_container {
      padding: 0 20px 60px 20px;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar { width: 0 !important };
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;

      .nav_1 {
        .item {
          font-size: 18px;
          padding-top: 18px;
          padding-bottom: 18px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #E2DDD4;
          &.active, &.nav_active {
            color: $orange;
          }
        }
      }

      .nav_2 {
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 20px;
        .wpml-ls-legacy-list-horizontal, .wpml-ls-link {
          padding: 0;
        }
        .item {
          font-size: 14px;
          width: 48%;
          flex-shrink: 0;
          margin-bottom: 18px;
        }
      }

    }

    .tel {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #DF613B;
      color: #FBFBFA;
      padding: 0 20px;
    }


  }

}


.post-type-archive-pages_business, .single-pages_business {

  header .nav_top .right .tel a {
     background-color: $business;
  }

  header .nav_main .right .item::before, header .nav_main .right .item::before {
    background-color: $business;
  }

  header .nav_main .right a.item:hover {
    color: $business;
  }

  header .nav-mobile .tel {
    background-color: $business;
  }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

  header .nav_top .right .tel a {
    background-color: $event;
  }

  header .nav_main .right .item::before, header .nav_main .right .item::before {
    background-color: $event;
  }

  header .nav_main .right a.item:hover {
    color: $event;
  }

  header .nav-mobile .tel {
    background-color: $event;
  }

}
