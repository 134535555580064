// Module - 09 - Contenu / Boîtes

.module-09-content_box {

    .box_container {
        display: flex;
        justify-content:space-around;
        flex-wrap: wrap;
        margin-bottom: -20px;

        .box {
            width: 100%;
            max-width: 315px;
            min-height: 240px;
            border: 1px solid $orange;
            padding: 40px 32px;
            border-radius: 12px;
            margin-bottom: 20px;
            margin-left: 30px;
            margin-right: 30px;
            overflow: hidden;
            @include break(mobile){
                margin-left: 5px;
                margin-right: 5px;
                min-height: 260px;
                height: auto;
            }

            .txt, .link {
                padding-top: 20px;
            }

            &.ghost {
                padding: 0;
                margin-bottom: 0px;
                min-height: 0;
                @include break(mobile){
                    display: none;
                }
            }

            small {
                font-size: 11px;
                flex-grow: 0;
                line-height: 1.5em;
                display: block;
                font-style: italic;
            }
        }
    }
}


.post-type-archive-pages_business, .single-pages_business {

    .module-09-content_box .box_container .box {
        border: 1px solid $business;
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-09-content_box .box_container .box {
        border: 1px solid $event;
    }

}