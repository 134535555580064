
// Module - 07 - Contenu / Avantages

.module-07-content_avantages {
    .title_top {
        padding-bottom: 60px;
    }
    
    .col_wrap {
        display: flex;
        align-items: center;
        @include break(tablet){
            flex-direction: column;
        }

        .col_list {
            width: 100%;
            flex-shrink: 1;
            column-count: 2;
            column-gap: 0;
            padding-left: 16px;
            @include break(tablet){
                column-count: 1;
            }

            li {
                padding-right: 25%;
                padding-bottom: 15px;
                padding-top: 15px;
                position: relative;
                padding-left: 25px;
                @include break(tablet){
                    padding-left: 20px;
                    padding-right: 0;
                    padding-top: 0;
                }
                &::before {
                    content: " ";
                    background-image: url('../img/check.svg');
                    background-position: center;
                    background-size: cover;
                    width: 35px;
                    height: 35px;
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: -20px;
                    @include break(tablet){
                        width: 25px;
                        height: 25px;
                        top: -1px;
                        left: -20px;
                    }
                }
            }
        }
        .col_img {
            width: 33%;
            @include break(tablet){
                width: 100%;
                margin-top: 35px;
            }
            .img {
                position: relative;
                border-radius: 12px;
                overflow: hidden;
                width: 100%;
                padding-bottom: 136%;
                img {
                    @include bg-img;
                }
            }
        }
    }

}