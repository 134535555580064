// Module – 04 – Contenu / Multi Colonnes

.module-04-content_multi-col {

    .multicol {
        margin-left: -21px;
        margin-right: -21px;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: -40px;

        .col {
            margin-left: 21px;
            margin-right: 21px;
            width: calc(33% - 38px);
            margin-bottom: $pad-med;
            @include break(tablet) {
                width: calc(50% - 42px);
            }
            @include break(mobile) {
                width: 100%;
            }

            .img_container {
                width: 100%;
                padding-bottom: 66.6%;
                position: relative;
                border-radius: 12px;
                overflow: hidden;

                img {
                    @include bg-img;
                    transition: all 0.8s;
                }
            }

            .content {
                padding-top: 25px;
                h5 {
                    transition: all 0.4s;
                }
                .txt {
                    padding-top: $pad-xxsmall;
                }

                .link {
                    padding-top: 25px;
                    &:hover {
                        color: $orange;
                    }
                }
            }

            &.ghost {
                margin-bottom: 0;
            }

            &:hover {
                color: $dark;
                h5 {
                    color: $orange;
                }
                img {
                    transform: translate(-50%,-50%) scale(1.05);
                }
                .link {
                    color: $dark-orange;
                }
            }
        }

        &.big-col .col {
            width: calc(50% - 42px);
            @include break(mobile) {
                width: 100%;
            }
        }
    }
}