.module-28-office {
    position: relative;

    .bg {
        @include bg-img;
        z-index: 0;
    }
    >div {
        position: relative;
        z-index: 2;
    }

    .top_nav {
        display: flex;
        padding-bottom: 80px;
        align-items: flex-end;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
            align-items: flex-start;
        }

        .title {
            width: 60%;
            @include break(tablet) {
                width: 100%;
            }

            .txt {
                padding-top: $pad-xsmall;
                @include break(tablet){
                  padding-bottom: $pad-small;
                }
              }
        }

        .nav {
            display: flex;
            align-items: center;
            @include break(tablet) {
                width: 100%;
                justify-content: space-between;
                padding-top: 30px;
            }
            .arrow {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;
                position: relative;
                transition: all 0.4s;
                margin-left: 14px;
                cursor: pointer;
                svg {
                    transition: all 0.4s;
                }
                @include break(tablet) {
                    margin-left: 0px;
                }
                &:hover {
                    transform: scale(1.1);
                    svg {
                        transform: scale(0.8);
                    }
                }

                &.not_active {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }

    .office_card {
        background-color: $orange;
        border-radius: 12px;
        width: calc(25% - 20px);
        min-width: 260px;
        height: 15.1vw;
        max-height: 215px;
        min-height: 185px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        position: relative;
        transition: all 0.4s;
        border: 1px solid rgba($color: $dark-mole, $alpha: 0);
        flex-shrink: 0;
        
        .pin {
            position: absolute;
            width: 57px;
            height: 57px;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            top: 20px;
            left: 20px;
            transition: all 0.4s;
        }

        .content {
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
            color: $white;
            transition: all 0.4s;
            h6 {
                position: relative;
                display: inline-block;
                span {
                    display: block;
                }
                svg {
                    position: absolute;
                    right: -17px;
                    top: -3px;
                    path {
                        transition: all 0.4s;
                    }
                }
            }
            p {
                font-size: 0.875em;
            }
        }

        &:hover {
            border: 1px solid rgba($color: $dark-mole, $alpha: 1);
            background-color: $white;
            .content {
                color: $orange;
                svg path {
                    fill:$orange;
                }
            } 
        }

        &-ghost {
            padding: 0;
            height: 0;
            min-height: 0;
            opacity: 0;
            background-color: transparent;
            @include break(mobile) {
                display: none!important;
            }
        }
    }


    .office_grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: -10px;
        margin-right: -10px;
        @include break(mobile) {
            margin-left: 0px;
            margin-right: 0px;
        }
        .office_card {
            @include break(mobile) {
                max-width: 500px;
                width: 100%;
                min-width: 0;
                margin-left: 0;
                margin-right: 0;
                display: flex;
                height: 105px;
                max-height: 105px;
                min-height: 105px;
                padding: 15px;
                align-items: center;

                .pin {
                    position: relative;
                    top: auto;
                    left: auto;
                }
                .content {
                    position: relative;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    padding-left: 30px;
                }
            }
        }
    }

    .office_slider {
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        &::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        min-height: -webkit-fill-available;
        padding-left: calc((100vw - 1325px) / 2);
        padding-right: calc((100vw - 1325px) / 2);
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        @include break(container){
            padding-left: 5%;
        }

        .office_card {
            scroll-snap-align: start;
            scroll-margin: calc((100vw - 1325px) / 2);
            @include break(container){
                scroll-margin: 5vw;
            }
            @include break(mobile) {
                margin-right: 5%;
            }
        }
    }
}


.post-type-archive-pages_business, .single-pages_business {

    .module-28-office .office_card {
        background-color: $business;
        .pin {
            svg path {
                fill:$business;
            }
        } 
        &:hover {
            border: 1px solid rgba($color: $dark-mole, $alpha: 1);
            background-color: $white;
            .content {
                color: $business;
                svg path {
                    fill:$business;
                }
            } 
        }
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-28-office .office_card {
        background-color: $event;
        .pin {
            svg path {
                fill:$event;
            }
        } 
        &:hover {
            border: 1px solid rgba($color: $dark-mole, $alpha: 1);
            background-color: $white;
            .content {
                color: $event;
                svg path {
                    fill:$event;
                }
            } 
        }
    }

}