// Module - 16 - Redirection / Bouton

.module-16-cta_btn {
    position: relative;
    padding-top: $pad-xlarge;
    padding-bottom: $pad-xlarge;
    @include break(tablet) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-size: cover;
        z-index: 1;

        &.bg-img_blue {
            @include break(mobile) {
                background-image: url(../img/bg-blue_mobile.svg)!important;
            }
        }
        &.bg-img_orange {
            @include break(mobile) {
                background-image: none!important;
            }
        }
    }

    .content {
        position: relative;
        z-index: 2;
        text-align: center;
        color: $white;
        width: 100%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        .txt {
            padding-top: $pad-xsmall;
        }

        .btn {
            margin-top: $pad-small;
        }
    }

    &.bg_orange {
        .content {
            .btn {
                background-color: $white;
                color: $orange;
                svg path {
                    fill: $orange;
                }
            }
        }
    }
}


.post-type-archive-pages_business, .single-pages_business {

    .module-16-cta_btn.bg_orange {
        .bg {
            background-image: none!important;
            background-color: $business;
        }

        .content {
            .btn {
                background-color: $white;
                color: $business;
                svg path {
                    fill: $business;
                }
            }
        }
    }
}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-16-cta_btn.bg_orange {
        .bg {
            background-image: none!important;
            background-color: $event;
        }

        .content {
            .btn {
                background-color: $white;
                color: $event;
                svg path {
                    fill: $event;
                }
            }
        }
    }

}