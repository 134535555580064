.module-23-team {

    .top_nav {
        display: flex;
        padding-bottom: 80px;
        align-items: flex-end;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
            align-items: flex-start;
        }

        .title {
            width: 60%;
            @include break(tablet) {
                width: 100%;
                padding-bottom: 30px;
            }
        }
        .txt {
            padding-top: $pad-xsmall;
            @include break(tablet){
              padding-bottom: $pad-small;
            }
          }

        .nav {
            display: flex;
            align-items: center;
            @include break(tablet) {
                width: 100%;
                justify-content: space-between;
            }
            .arrow {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;
                position: relative;
                transition: all 0.4s;
                margin-left: 14px;
                cursor: pointer;
                svg {
                    transition: all 0.4s;
                }
                @include break(tablet) {
                    margin-left: 0px;
                }
                &:hover {
                    transform: scale(1.1);
                    svg {
                        transform: scale(0.8);
                    }
                }

                &.not_active {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }

    .team_card {
        width: 315px;
        min-height: 410px;
        margin-right: 1.5%;
        flex-shrink: 0;
        cursor: pointer;
        scroll-snap-align: start;
        scroll-margin: calc((100vw - 1325px) / 2);
        border-radius: 12px;
        background-color: $white;
        padding: 13px;
        @include break(container){
            scroll-margin: 5vw;
        }
        @include break(mobile) {
            width: 90%;
            margin-right: 5%;
        }

        .img_container {
            width: 100%;                
            padding-bottom: 115%;
            position: relative;
            border-radius: 12px;
            overflow: hidden;

            img {
                @include bg-img;
            }

            .dest {
                position: absolute;
                bottom: 8px;
                right: 8px;
                background-color: $dark-mole;
                border-radius: 40px;
                padding: 8px 14px;
                display: flex;
                align-items: center;
                font-size: 15px;
                line-height: 1em;

                svg {
                    width: 17px;
                    margin-right: 8px;
                }

                span {
                    display: block;
                    margin-bottom: -0.15em;
                }
            }
        }
        .content {
            padding-top: 12px;
            h6 {
                padding-bottom: 0;
            }
            .poste {
                font-size: 0.85em;
            }
            .succ {
                padding-top: 14px;
                display: flex;
                align-items: center;
                font-size: 0.85em;
                line-height: 1em;
                img {
                    margin-right: 5px;
                    width: 14px;
                }
                .coma {
                    margin-right: 0.4em;
                    &:last-of-type {
                        display: none;
                    }
                }
            }
        }

        &-ghost {
            padding: 0;
            height: 1px;
            opacity: 0;
            min-height: 0;
            @include break(mobile){
                display: none;
             }
        }
    }


    .slider_team {
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        &::-webkit-scrollbar { width: 0 !important };
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        min-height: -webkit-fill-available;
        padding-left: calc((100vw - 1325px) / 2);
        padding-right: calc((100vw - 1325px) / 2);
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        @include break(container){
            padding-left: 5%;
        }
    }

    .tab_team {
        .tab_nav {
            overflow-x: scroll;
            overflow-y: hidden;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            padding-bottom: 40px;

            .tab_nav-inner {
                display: flex;
                .tab_name {
                    margin-right: 30px;
                    font-size: 1.2em;
                    @include font-medium;
                    flex-shrink: 0;
                    position: relative;
                    transition: all 0.4s;
                    padding-bottom: 9px;
                    cursor: pointer;

                    &::after {
                        content: " ";
                        position: absolute;
                        bottom: 4px;
                        left: 0;
                        width: 0%;
                        height: 4px;
                        background-color: $dark;
                        transition: all 0.4s;
                    }

                    &:hover, &.active {
                        color: $orange;
                        &::after { 
                            width: 100%;
                            background-color: $orange;
                        }
                    }

                    &.active {
                        pointer-events: none;
                    }
                }
                .tab_ghost {
                    flex-shrink: 0;
                    width: calc((100vw - 1325px) / 2);
                    @include break(container){
                        width: 5%;
                    }
                }
            }
        }

        .tab_container {
            position: relative;
            height: 1000px;
            .tab {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                display: none;
                @include break(mobile){
                   flex-direction: column;
                   align-items: center;
                }
                .team_card {
                    margin-bottom: 20px;
                    margin-right: 10px;
                    scroll-margin: 0;
                    @include break(small-screen){
                        margin-right: 0px;
                        width: 250px;
                    }
                    @include break(mobile){
                        margin-right: 0px;
                    }
                    
                }

                &:first-of-type {
                    display: flex;
                }
            }
        }
    }


}


.post-type-archive-pages_business, .single-pages_business {

    .module-23-team .tab_team .tab_nav .tab_nav-inner .tab_name { 
        &:hover, &.active {
            color: $business;
            &::after { 
                background-color: $business;
            }
        }
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-23-team .tab_team .tab_nav .tab_nav-inner .tab_name { 
        &:hover, &.active {
            color: $event;
            &::after { 
                background-color: $event;
            }
        }
    }

}