// Module - 08 - Contenu / Culture

.module-08-content_culture {
    height: 100vh;
    position: relative;
    @include break(tablet){
        height: 500px;
    }

    .content {
        @include absolute-center(center);
        width: 90%;
        max-width: 750px;
        text-align: center;
        z-index: 5;

        .title-h1 {
            color: $orange;
        }
    }

    .img-box {
        position: absolute;
        z-index: 3;

        .img {
            position: relative;
            width: 185px;
            height: 185px;
            overflow: hidden;
            border-radius: 12px;
            z-index: 2;
            @include break(tablet){
                width: 85px;
                height: 85px;
                border-radius: 5px;
            }

            img {
                @include bg-img;
            }
        }

        .bg {
            position: absolute;
            border-radius: 12px;
            width: 185px;
            height: 185px;
            background-color: $powder-blue;
            right: -10px;
            bottom: -10px;
            overflow: hidden;
            z-index: 1;
            @include break(tablet){
                width: 85px;
                height: 85px;
                border-radius: 6px;
            }
        }

        &.img-box_1 {
            z-index: 3;
            right: 15%;
            top: 10px;
            transform: rotate(5deg);
            svg {
                position: absolute;
                right: -20px;
                transform: translateX(100%) rotate(-5deg);
                top: 35px;
                @include break(tablet){
                    top: 5px;
                    width: 25px;
                }
            }
            @include break(tablet){
                right: 22%;
            }
        }
        &.img-box_2 {
            z-index: 3;
            left: 7.5%;
            top: 150px;
            transform: rotate(8deg);
            @include break(tablet){
                display: none;
            }
            svg {
                position: absolute;
                left: 25px;
                bottom: -10px;
                transform: translate(-100%,100%) rotate(-8deg);
            }
        }
        &.img-box_3 {
            z-index: 2;
            right: 6.5%;
            top: 170px;
            transform: rotate(-6deg);
            @include break(tablet){
                right: 5%;
                top: 85px;
            }
        }
        &.img-box_4 {
            z-index: 2;
            left: 25%;
            bottom: 20px;
            transform: rotate(-12deg);
            @include break(tablet){
                left: 5%;
                bottom: 10px;
            }
        }
    }

    .culture-tag {
        position: absolute;
        border-radius: 40px;
        background-color: $orange;
        padding: 12px 20px;
        @include font-medium;
        color: $white;
        transform-origin: left top;
        z-index: 4;
        @include break(tablet){
            padding: 5px 8px;
            font-size: 12px;
        }

        &.culture-tag_1 {
            top: 0;
            left: 22.5%;
            transform: rotate(9deg);
            @include break(tablet){
                left: 10%;
                top: 50px;
            }
        }
        &.culture-tag_2 {
            top: 100px;
            left: 40%;
            transform: rotate(-8deg);
            background-color: $blue;
            @include break(tablet){
                left: auto;
                right: 20%;
                top: 120px;
                transform: rotate(-5deg);
            }
        }
        &.culture-tag_3 {
            bottom: 140px;
            left: 28%;
            transform: translateX(-100%) rotate(8deg);
            background-color: $blue;
            transform-origin: right bottom;
            @include break(tablet){
                bottom: 85px;
                left: 22%;
                transform: translateX(-50%) rotate(8deg);
            }
        }
        &.culture-tag_4 {
            bottom: 140px;
            right: 20%;
            transform:rotate(5deg);
            background-color: $powder-blue;
            color: $dark;
            @include break(tablet){
                bottom: 70px;
                right: 1%;
            }
        }
        &.culture-tag_5 {
            bottom: 40px;
            right: 35%;
            transform:rotate(-8deg);
            svg {
                position: absolute;
                top: -30px;
                left: -30px;
                @include break(tablet){
                    width: 20px;
                    top: -12px;
                    left: -12px;
                }
            }
            @include break(tablet){
                bottom: 20px;
                right: 35%;
            }
        }
    }

    .heart {
        position: absolute;
        top: 100px;
        left: 26%;
        @include break(tablet){
            width: 70px;
            left: 5%;
            top: 90px;
        }
    }
}


.post-type-archive-pages_business .module-08-content_culture, .single-pages_business .module-08-content_culture {

    .module-08-content_culture .content, .title-h1 {
        color: $business;
    }

    .module-08-content_culture .culture-tag {
        background-color: $business;
    }

}

.post-type-archive-pages_events .module-08-content_culture, .single-pages_events .module-08-content_culture , .post-type-archive-realisations .module-08-content_culture , .single-realisations .module-08-content_culture {

    .module-08-content_culture .content, .title-h1 {
        color: $event;
    }

    .module-08-content_culture .culture-tag {
        background-color: $event;
    }

}