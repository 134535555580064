// Module - 10 - Contenu / Texte

.module-10-content_texte {
    
    .container {
        padding-left: 5%;
        padding-right: 5%;
        @include break(tablet){
            padding-left: 0;
            padding-right: 0;
        }   
    }
    .title-h2 {
        padding-bottom: $pad-med;
        @include break(tablet){
            padding-bottom: $pad-med_mobile;
        }
    }
}