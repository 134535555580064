

@keyframes spin {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(359);
    }
  }

// @keyframes fadeIn {
//   0%{
//     opacity: 0;
//   }
//   100%{
//     opacity: 1;
//   }
// }
//
// @keyframes fadeOut {
//   0%{
//     opacity: 1;
//   }
//   100%{
//     opacity: 0;
//   }
// }
