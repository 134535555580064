/*  --- Variable ---  */
$font-size: 18px;
$header-h: 120px;
$header-h_mobile: 112px;
$container: 1325px;

/*  --- Padding ---  */
$pad-xxsmall: 8px;
$pad-xsmall: 16px;
$pad-small: 32px;
$pad-med: 42px;
$pad-large: 80px;
$pad-xlarge: 120px;

$pad-small_mobile: 24px;
$pad-med_mobile: 32px;
$pad-large_mobile: 40px;
$pad-xlarge_mobile: 40px;

//COLOR
$dark: #242421;
$white: #FBFBFA;
$off-white: #F1EFEC;
$mole: #E7E4DE;
$dark-mole: #E1DDD4;
$grey: #BDBAB2;
$dark-grey: #5D5D5A;

$orange: #DF613B;
$dark-orange: #C9441C;
$blue: #123544;
$light-blue: #83C9D5;
$powder-blue: #CEE4EE;

$business: #A58455;
$event: #185D76;