// Module – 05 – Contenu / Partenaires

.module-05-content_parteners {

    // TYPE: LISTE
    &.type-list {
        .partner {
            display: flex;
            padding-top: $pad-med;
            padding-bottom: $pad-med;
            min-height: 240px;
            border-top: 1px solid $dark-mole;

            &:last-of-type {
                border-bottom: 1px solid $dark-mole;
            }
            @include break(tablet) {
                flex-direction: column;
                padding-top: $pad-med_mobile;
                padding-bottom: $pad-med_mobile;
                min-height: 0;
            }

            .logo_container {
                width: 205px;
                flex-shrink: 0;
                @include break(desktop-only) {
                    order: 3;
                }
                @include break(tablet) {
                    padding-bottom: 25px;
                }
                
                .logo {
                    // background-color: $white;
                    border-radius: 12px;
                    overflow: hidden;
                    padding: 20px 25px;
                    width: 100%;
                    height: 115px;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        @include absolute-center(center);
                        object-fit: contain;
                    }
                }
            }

            .title_container {
                width: 270px;
                flex-shrink: 0;
                @include break(small-screen) {
                    width: 200px;
                }
                @include break(tablet) {
                    width: 100%;
                    padding-bottom: 18px;
                }
    
                p {
                    padding-top: $pad-xsmall;
                    @include break(tablet) {
                        padding-top: 4px;
                    }
                }
            }

            .txt_container {
                padding-left: 5%;
                padding-right: 10%;
                width: 100%;
                flex-shrink: 1;
                .txt {
                    font-size: 15px;
                }
                @include break(tablet) {
                    padding-left: 0%;
                    padding-right: 0%;
                }

                .link {
                    padding-top: 25px;
                }
            }
        }

        &.bg_mole {
            .partner {
                border-top: 1px solid $grey;
                &:last-of-type {
                    border-bottom: 1px solid $grey;
                }
            }
        }
    }


    // TYPE: BOITE
    &.type-box {

        .partner_container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: -$pad-xsmall;

            .partner {
                border-radius: 12px;
                border: 2px solid $orange;
                padding: 40px 40px 50px 40px;
                width: 49.5%;
                margin-bottom: $pad-xsmall;
                @include break(tablet) {
                    width: 100%;
                    padding: 40px 32px;
                }

                .logo {
                    height: auto;
                    margin-bottom: 60px;
                
                    @include break(tablet) {
                        margin-bottom: $pad-small;
                    }

                    img {
                        height: 105px;
                        width: auto;
                    }
                }

                .title_container {
                    p {
                        padding-top: $pad-xsmall;
                        @include break(tablet) {
                            padding-top: 4px;
                        }
                    }
                }

                .txt_container {
                    padding-top: 20px;
                    .link {
                        padding-top: $pad-med;
                    }
                }

            }
            
            &.big_box .partner {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 75px 60px;
                margin-bottom: 0;
                @include break(tablet) {
                    flex-direction: column;
                    padding: 35px 25px 35px 25px;
                }

                .logo_container {
                    width: 30%;
                    flex-shrink: 0;
                    justify-content: space-between;
                    @include break(desktop-only) {
                        order: 3;
                    }
                    @include break(tablet) {
                        width: auto;
                        height: 105px;
                    }

                    .logo {
                        width: 100%;
                        margin-bottom: 0;
                        height: auto;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                .txt_container {
                    width: 100%;
                    flex-shrink: 1;
                    padding-right: 7.5%;
                    @include break(tablet) {
                        padding-right: 0;
                    }

                    .link {
                        margin-top: $pad-med;
                        display: inline-flex;
                        align-items: center;
                        padding: 16px 32px 14px 32px;
                        border-radius: 42px;
                        background-color: $orange;
                        color: $white;
                        line-height: 100%;
                        font-size: 14px;
                        text-transform: uppercase;
                        @include font-medium;
                        svg {
                            margin-left: 12px;
                            path {
                                fill: $white;
                            }
                        }
                    }
                }
            }
        }
    } 

    &.type-logo {
        .title_top {
            padding-right: 20%;
            padding-left: 20%;
            text-align: center;
            padding-bottom: 60px;
            display: flex;
            justify-content: center;
            @include break(mobile) {
                padding-right: 0%;
                padding-left: 0%;
                padding-bottom: 40px;
            }
            .title-h2 {
                font-size: 1.4em;
            }
        }

        .logo_row {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: -20px;

            .logo {
                margin-left: 10px;
                margin-right: 10px;
                width: 180px;
                height: 75px;
                margin-bottom: 20px;
                position: relative;
                img {
                    @include bg-img;
                    object-fit: contain;
                }
            }
        }
    }
    
}



.post-type-archive-pages_business, .single-pages_business {

    .module-05-content_parteners.type-box .partner_container .partner{
        border: 2px solid $business;
    }

    .module-05-content_parteners.type-box .partner_container.big_box .partner .txt_container .link {
        background-color: $business;
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-05-content_parteners.type-box .partner_container .partner{
        border: 2px solid $event;
    }

    .module-05-content_parteners.type-box .partner_container.big_box .partner .txt_container .link {
        background-color: $event;
    }

}