@import 'partials/_mixin';
@import 'partials/_function';

@import "./base/variable";
@import "./base/wordpress";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';
@import './base/layout';

@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/anim-class";

@import './pages/template-faq';
@import './pages/template-brochures';
@import './pages/template-team';
@import './pages/template-promotions';
@import './pages/template-job';
@import './pages/template-voyages';
@import './pages/template-actualites';
@import './pages/template-blogue';
@import './pages/template-archive-blogue';
@import './pages/quatrecentquatre';


@import './modules/module-01-header';
@import './modules/module-02-header_2col';
@import './modules/module-03-intro';
@import './modules/module-04-content_multi-col';
@import './modules/module-05-content_parteners';
@import './modules/module-06-content_col-txt-img';
@import './modules/module-07-content_avantages';
@import './modules/module-08-content_culture';
@import './modules/module-09-content_box';
@import './modules/module-10-content_texte';
@import './modules/module-11-content_video';
@import './modules/module-12-content_icon';
@import './modules/module-13-content_collapse';
@import './modules/module-14-cta_simple-arrows';
@import './modules/module-15-cta_bg-img';
@import './modules/module-16-cta_btn';
@import './modules/module-17-cta_box';
@import './modules/module-18-blogue';
@import './modules/module-19-faq';
@import './modules/module-20-map.scss';
@import './modules/module-21-slider_chrono';
@import './modules/module-22-slider_key';
@import './modules/module-23-team';
@import './modules/module-24-top';
@import './modules/module-25-dest';
@import './modules/module-26-promo';
@import './modules/module-27-form';
@import './modules/module-28-office';
@import './modules/module-29-temoignage';
@import './modules/module-30-actu';
@import './modules/module-31-travel';
@import './modules/module-32-travel-dest';
@import './modules/module-33-infos';
@import './modules/module-34-realisations';
@import './modules/module-35-hubvoyages';
