html {
	overflow-x: hidden;
	overflow-y: scroll;
	&.overflow {
		overflow: hidden;
	}
	background-color: $off-white;
	color: $dark;
	&::-webkit-scrollbar {
		width: 0 !important;
	}
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	min-height: -webkit-fill-available;
	height: -webkit-fill-available;
}

body {
	padding: 0;
	margin: 0;
	min-height: 100vh;
	&.overflow {
		overflow: hidden;
	}
	background-color: $off-white;
	color: $dark;
	font-size: $font-size;
	@include break(mobile) {
		font-size: 16px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	@include font-regular;
	font-style: normal;
	min-height: 100vh;
	min-height: -webkit-fill-available;

	&.admin-bar header {
		margin-top: 30px;
	}
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
	body {
		/* The hack for Safari */
		min-height: -webkit-fill-available;
	}
}

// .loader {
// position: fixed;
// top: 0;
// left: 0;
// right: 0;
// height: 100vh;
// z-index: 9999;
// z-index: 9999999999;
// background-color: #ffffff;
// text-align: center;
// }

.container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	max-width: $container;
}

.small-container {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	max-width: 990px;
	@include break(mobile) {
		width: 90%;
	}
}

.flex {
	display: flex;
	justify-content: space-between;
	> * {
		width: 50%;
	}
}

.mobile {
	display: none;
	@include break(mobile) {
		display: block;
	}
}
.desktop {
	@include break(mobile) {
		display: none;
	}
}

.tablet {
	display: none;
	@include break(tablet) {
		display: block;
	}
}
.desktop-tablet {
	@include break(tablet) {
		display: none;
	}
}

a {
	color: inherit;
	text-decoration: none;
	transition: all 0.3s;

	&:hover {
		color: $orange;
	}
}

h1,
h2,
h3,
h4 {
	a {
		color: inherit;
		text-decoration: none;
	}
}

p a {
	color: $orange;
}

ul {
	padding-left: 22px;
}

.ghost {
	height: 0 !important;
	padding: 0;
	margin: 0;
	border: none !important;
}

h1,
.title-h1 {
	@include font-title;
	font-size: 5.5em;
	line-height: 120%;
	margin: 0;
	@include break(tablet) {
		font-size: 2.75em;
	}
}
h1.title-h1_small,
.title-h1_small {
	@include font-title;
	font-size: 4em;
	line-height: 120%;
	margin: 0;
	@include break(tablet) {
		font-size: 2.5em;
	}
}
h2,
.title-h2 {
	@include font-title;
	font-size: 3em;
	line-height: 120%;
	margin: 0;
	@include break(tablet) {
		font-size: 2.25em;
	}
}
h3,
.title-h3 {
	@include font-title;
	font-size: 2.4em;
	line-height: 120%;
	margin: 0;
	@include break(tablet) {
		font-size: 2em;
	}
}
h4,
.title-h4 {
	@include font-medium;
	font-size: 1.55em;
	line-height: 120%;
	margin: 0;
	@include break(tablet) {
		font-size: 1.375em;
	}
}
h5,
.title-h5 {
	@include font-medium;
	font-size: 1.33em;
	line-height: 120%;
	margin: 0;
	@include break(tablet) {
		font-size: 1.25em;
	}
}
h6,
.title-h6 {
	@include font-medium;
	font-size: 1.11em;
	line-height: 120%;
	margin: 0;
	padding-bottom: $pad-xxsmall;
	@include break(tablet) {
		font-size: 1.125em;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}

// Breadcrumb
.breadcrumb {
	display: flex;
	list-style-type: none;
	margin: 0;
	align-items: center;
	padding-bottom: $pad-xsmall;

	p,
	a {
		margin: 0;
		padding: 0;
		display: block;
		margin-right: 0.65em;
		font-size: 0.85em;
		@include font-medium;
		flex-shrink: 0;

		@include break(tablet) {
			max-width: 150px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

strong {
	@include font-medium;
}

// Background
.bg_light-grey {
	background-color: $off-white;
}
.bg_mole {
	background-color: $mole;
}
.bg_blue {
	background-color: $blue;
}
.bg_orange {
	background-color: $orange;
}
.bg_business {
	background-color: $business;
}
.bg_event {
	background-color: $event;
}

// Boutons / Link
a.btn {
	display: inline-flex;
	align-items: center;
	padding: 16px 32px 14px 32px;
	border-radius: 42px;
	background: linear-gradient(
		to left,
		$orange,
		$orange 50%,
		$dark-orange 75%,
		$dark-orange 100%
	);
	background-size: 500% 100%;
	background-position-x: 100%;
	color: $white;
	line-height: 100%;
	font-size: 14px;
	text-transform: uppercase;
	@include font-medium;
	transition: all 0.8s;
	svg {
		margin-left: 12px;
	}

	&:hover {
		background-position-x: 0%;
	}
}

.link {
	color: $orange;
	display: flex;
	font-size: 14px;
	@include font-medium;
	text-transform: uppercase;
	align-items: center;
	transition: all 0.3s;
	svg {
		margin-left: 12px;
		transition: all 0.3s;
		path {
			transition: all 0.3s;
		}
	}

	&:hover {
		color: $dark-orange;
		svg {
			margin-left: 18px;
		}
		path {
			fill: $dark-orange;
		}
	}
}

// Titre h2 - section
.title_top {
	padding-right: 40%;
	padding-bottom: $pad-large;
	@include break(tablet) {
		padding-right: 0%;
		padding-bottom: $pad-large_mobile;
	}

	.txt {
		padding-top: $pad-xsmall;
		@include break(tablet) {
			padding-bottom: $pad-small;
		}
	}

	&.title_top_2col {
		padding-right: 0;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		.left {
			width: 40%;
			@include break(tablet) {
				width: 100%;
			}
		}
	}
}

.small-container.title_top {
	padding-bottom: 60px;
	padding-right: 0%;
	.txt {
		padding-right: 40%;
		padding-bottom: 0;
	}
}

// Padding
.sec-padding_grand {
	@include padding-section_big;
}

.sec-padding_petit {
	@include padding-section;
}
.sec-padding_notop {
	@include padding-section;
	padding-top: 0;
}
.sec-padding_notop {
	@include padding-section;
	padding-bottom: 0;
}

// POPUP
.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	z-index: 999999;
	align-items: center;
	justify-content: center;

	.inner {
		position: absolute;
		width: 80%;
		height: 80vh;
		transform-origin: center;
		display: flex;
		align-items: center;
		@include absolute-center(center);
		z-index: 2;
		pointer-events: none;
	}

	.video_wrap {
		pointer-events: all;
		width: 100%;
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;

		iframe,
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		z-index: 2;
		display: flex;
		align-items: center;
		transition: all 0.4s;
		span {
			font-style: italic;
		}

		svg path {
			transition: all 0.4s;
		}

		&:hover {
			color: $orange;
			svg path {
				fill: $orange;
				stroke: $orange;
			}
		}
	}

	.bg {
		background: rgba($off-white, 0.9);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
}

.pum-container {
	.pum-title,
	.popup-cta-button {
		font-family: "Gotham", sans-serif !important;
		font-weight: 500 !important;
	}
}

.post-type-archive-pages_business,
.single-pages_business {
	a.btn {
		background: linear-gradient(
			to left,
			$business,
			$business 50%,
			$dark 75%,
			$dark 100%
		);
		background-size: 500% 100%;
		background-position-x: 100%;
		&:hover {
			background-position-x: 0%;
		}
	}

	a.link {
		color: $business;
		svg path {
			fill: $business;
		}
	}

	.bg_orange {
		background-color: $business;
	}
}

.post-type-archive-pages_events,
.single-pages_events,
.post-type-archive-realisations,
.single-realisations {
	a.btn {
		background: linear-gradient(
			to left,
			$event,
			$event 50%,
			$blue 75%,
			$blue 100%
		);
		background-size: 500% 100%;
		background-position-x: 100%;
		&:hover {
			background-position-x: 0%;
		}
	}

	a.link {
		color: $event;
		svg path {
			fill: $event;
		}
	}

	.bg_orange {
		background-color: $event;
	}
}

.sticky-price-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 15px 5%;
	background-color: $dark;
	color: $white;
	box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
	z-index: 999;
	transition: opacity 0.3s ease;
	opacity: 0;
	align-items: center;
	justify-content: space-between;
	gap: 3%;

	@include break(tablet) {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}

	&.visible {
		opacity: 1;
	}

	&-title {
		display: flex;
		flex-direction: column;
		gap: 5px;

		@include break(tablet) {
			flex-direction: row;
			align-items: center;
		}

		.title-h3 {
			font-size: 20px;

			@include break(tablet) {
				font-size: 18px;
			}
		}

		.stars {
			display: flex;
			align-items: center;
		}
	}

	&-localisation,
	&-date {
		.info {
			display: flex;
			flex-direction: column;
			gap: 5px;

			@include break(tablet) {
				flex-direction: row;
				gap: 25px;
			}
		}

		.icon {
			display: flex;
			gap: 10px;

			img {
				height: auto;
				width: 100%;
				max-width: 20px;
				object-fit: contain;
				filter: brightness(0) invert(1);
			}

			span {
				@include font-light;
				font-size: 14px;
				font-style: italic;
			}
		}

		p {
			font-size: 16px;
		}
	}

	.price_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
    
		@include break(tablet) {
      flex-wrap: wrap;
      gap: 10px;
		}
	}

	.logo-price {
		width: 50px;
		height: 50px;
		margin-right: 15px;
	}

	.price-info {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.price-wrapper {
		display: flex;
		flex-direction: column;

		@include break(tablet) {
			flex-direction: row;
			align-items: center;
			gap: 8px;
		}
	}

	.price {
		font-size: 20px;
		font-weight: bold;
		margin: 0;
	}

	.price_full {
		text-decoration: line-through;
		margin: 0;
		margin-top: -5px;
		font-size: 14px;

		@include break(tablet) {
			margin-top: 0;
		}
	}

	.text_small,
	.text_taxe {
		font-size: 0.8rem;
		margin: 0;
	}

	.text_small {
		@include font-light;
		font-style: italic;
		text-wrap: nowrap;
	}

	.sticky-price-bar {
		.cta-container {
			display: flex;
			align-items: center;

			@include break(tablet) {
				width: 100%;
				justify-content: center;
				margin-top: 10px;
			}
		}

		.btn {
			white-space: nowrap;
		}
	}
}
