.template-team {

    .hero-team {
        padding-top: 160px;
        padding-bottom: 120px;
        @include break(tablet) {
            padding-bottom: 60px;
        }
        .flex {
            @include break(tablet) {
                flex-direction: column-reverse;
            }
        }
        .img {
            width: 316px;
            flex-shrink: 0;
            position: relative;
            @include break(tablet) {
                display: none;
            }
            .img_container {
                width: 316px;
                height: 435px;
                overflow: hidden;
                border-radius: 12px;
                position: sticky;
                top: 160px;
                left: 0;
            }
            img {
                @include bg-img;
            }
        }
        .content {
            width: 60%;
            @include break(tablet) {
                width: 100%;
                margin-bottom: 60px;
            }
            .breadcrumb {
                padding-bottom: 30px;
            }
            h5 {
                padding-top: 5px;
            }
            .btn {
                margin-top: 60px;
                @include break(tablet) {
                    margin-top: 30px;
                }
            }

            .img_tablet {
                display: none;
                @include break(tablet) {
                    display: flex;
                    margin-top: 60px;
                    justify-content: center;
                }
                .img_container {
                    width: 100%;
                    max-width: 335px;
                    height: 335px;
                    overflow: hidden;
                    border-radius: 12px;
                    position: relative
                }
                img {
                    @include bg-img;
                }
            }
        }
    }

    .presentation {
        padding-top: 200px;
        @include break(tablet){
            padding-top: 80px;
        }

            .txt {
                padding-bottom: 40px;
            }

            .info {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                .icon {
                    width: 81px;
                    height: 81px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $mole;
                    border-radius: 50%;
                    margin-right: 30px;
                    img {
                        width: 41px;
                    }
                }
            }
        
    }

}