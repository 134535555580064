.module-35-hubvoyages {

    .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include break(mobile) {
            flex-direction: column;
            justify-content: center;
         }


        .dest_card {
            position: relative;
            width: 32.5%;
            padding-bottom: 43.8%;
            border-radius: 12px;
            overflow: hidden;
            transition: none;
            cursor: pointer;
            transform-origin: center;
            margin-bottom: 1.5%;
            @include break(mobile) {
               width: 100%;
               padding-bottom: 135%;
               margin-bottom: 12px;
            }

            .img_container {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                img {
                    @include bg-img;
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: rgba(71, 65, 60, 0.4);
                backdrop-filter: blur(0px);
                transition: backdrop-filter 0.6s;
            }

            .content {
                text-align: center;
                z-index: 3;
                width: 80%;
                @include absolute-center(center);
                .tag {
                    padding: 5px 8px 3px 8px;
                    background-color: $white;
                    border-radius: 6px;
                    color: $orange;
                    font-size: 13px;
                    margin-bottom: 15px;
                    display: block;
                    width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                    @include font-medium;
                }
                h4 {
                    color: $white;
                }
            }

            &:hover {
                .overlay {
                    backdrop-filter: blur(5px);
                }
            }

            &_ghost {
                padding-bottom: 0;
                opacity: 0;
                height: 1px;
            }
        }

        &-4 {
            @include break(mobile) {
                flex-direction: row;
                justify-content: space-between;
            }
            .dest_card {
                width: 24%;
                padding-bottom: 32.5%;
                margin-bottom: 1.5%;
                @include break(mobile) {
                    width: 48%;
                    padding-bottom: 63%;
                    margin-bottom: 12px;

                    h4 {
                        font-size: 22px;
                    }
                }
                &_ghost {
                    padding-bottom: 0;
                    opacity: 0;
                    height: 1px;
                }
            }
        }
    }

    .slider-dest_container {
        position: relative;
        width: 100%;
        padding-bottom: 47.3%;
        @include break(mobile){
            padding-bottom: 134.5%;
        }

        .slider-dest_slide {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: none;

            &:first-of-type {
                display: block;
            }

            .img_container {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 100%;
                img {
                    @include bg-img;
                    z-index: 1;
                }
                .overlay {
                    z-index: 2;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.60) 100%), rgba(36, 36, 33, 0.2);
                    mix-blend-mode: multiply;
                }
            }

            .titre {
                z-index: 2;
                position: absolute;
                left: calc((100vw - 1325px) / 2);
                bottom: 10%;
                display: flex;
                align-items: center;
                color: $white;
                transition: color 0.4s;
                width: 50%;
                @include break(container){
                    left: 5%;
                }
                @include break(mobile){
                    width: 100%;
                    bottom: 8%;
                }

                svg {
                    transition: all 0.4s;
                    margin-left: 18px;
                    @include break(mobile){
                        width: 37px;
                    }
                }

                &:hover {
                    color: $orange;
                    svg {
                        margin-left: 28px;
                    }
                }
            }
        }

        &.slider-dest {
            .slider-dest_slide {
                .titre {
                    @include break(mobile){
                        bottom: auto;
                        top: 12%;
                    }
                }
            }

            .slider-dest_nav {
                position: absolute;
                z-index: 10;
                bottom: 10%;
                right: calc((100vw - 1325px) / 2);
                display: flex;
                align-items: center;
                @include break(container){
                    right: 5%;
                }
                @include break(mobile){
                    bottom: 8%;
                    left: 5%;
                    justify-content: space-between;
                }

                .nb_slide {
                    display: flex;
                    align-items: center;
                    color: $white;
                    font-size: 15px;
                    margin-right: 60px;
                    line-height: 1em;
                    .sep {
                        margin-left: 5px;
                        margin-right: 4px;
                        font-size: 15px;
                    }
                }
                .arrows_wrap {
                    display: flex;
                    align-items: center;
                    .arrow {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 3;
                        position: relative;
                        margin-left: 14px;
                        transition: all 0.4s;
                        cursor: pointer;
                        svg {
                            transition: all 0.4s;
                        }

                        &:hover {
                            transform: scale(1.1);
                            svg {
                                transform: scale(0.8);
                            }
                        }
                    }
                }
            }
        }
    }

}
