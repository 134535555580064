// Module - 02 - Header / 2 colonnes

.module-02-header_2col {
    padding-top: calc(#{$header-h} + 60px);
    padding-bottom: $pad-large;
    @include break(mobile){
        padding-top: calc(#{$header-h_mobile} + 40px);
        padding-bottom: $pad-xlarge_mobile;
    } 

    .flex {
        align-items: center;
        @include break(tablet){
            flex-direction: column-reverse;
            padding-left: 5%;
            padding-right: 5%;
        } 
        .img {
            width: 40%;
            padding-left: 20px;
            @include break(tablet){
                width: 100%;
                padding-left: 0px;
                padding-top: $pad-xlarge_mobile;
            }
            .img_container {
                width: 100%;
                padding-bottom: 70%;
                border-radius: 12px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                @include break(tablet){
                    border-radius: 8px;
                }
            }
            img {
                @include bg-img;
            }
        }
        .content {
            padding-right: calc((100vw - #{$container}) / 2);
            padding-left: 5%;
            width: 60%;
            @include break(container){
                padding-right: 5%;
            } 
            @include break(tablet){
                width: 100%;
                padding-left: 0px;
                padding-right: 0px;
            }

            .breadcrumb {
                padding-bottom: $pad-xsmall;
            }

            .txt {
                padding-top: $pad-xxsmall;
            }

            .btn {
                margin-top: $pad-med;
                @include break(tablet){
                    margin-top: $pad-med_mobile;
                }
            }

            .logos_wrap {
                padding-top: $pad-med;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @include break(tablet){
                    padding-top: $pad-med_mobile;
                }

                .logo {
                    height: 72px;
                    width: 195px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: $pad-xxsmall;
                    margin-bottom: $pad-xxsmall;
                    @include break(tablet){
                        height: 40px;
                        width: 110px;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }

}