// Module - 14 - Redirection / Simple (liens avec flèches)

.module-14-cta_simple-arrows {
    background-color: $dark;
    padding: 100px 0 ;
    color: $white;

    @include break(tablet){
        padding: 60px 0 ;
    }

    .cta_container {
        display: flex;
        justify-content: space-between;
        @include break(tablet){
           flex-direction: column;
        }
        
        .title {
            width: 550px;
            padding-right: 50px;
            @include break(tablet){
                width: 100%;
                padding-right: 0px;
            }
        }

        .links_container {
            width: 40%;
            @include break(tablet){
                width: 100%;
                padding-top: $pad-small;
            }
            
            a {
                margin-bottom: $pad-small;
                color: $white;
                font-size: 1.33em;
                text-transform: none;
                @include font-regular;
                transition: all 0.4s;
                @include break(tablet){
                    margin-bottom: $pad-small_mobile;
                }
                svg {
                    transition: all 0.4s;
                }

                &:hover {
                    color: $orange;
                    svg {
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}


.post-type-archive-pages_business, .single-pages_business {

    .module-14-cta_simple-arrows {
        background-color: $business;
        .cta_container .links_container a svg path{
            fill: $white;
        }
        .cta_container .links_container a:hover {
            color: $dark;
            svg path{
                fill: $dark;
            }
        }
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-14-cta_simple-arrows {
        background-color: $event;
        .cta_container .links_container a svg path{
            fill: $white;
        }
        .cta_container .links_container a:hover {
            color: $blue;
            svg path{
                fill: $blue;
            }
        }
    }

}