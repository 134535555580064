.module-jobs {

    .job {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        background-color: $mole;
        padding: 40px 50px;
        margin-bottom: 12px;
        @include break(tablet) {
            flex-wrap: wrap;
            padding: 30px 20px;
        }

        >div {
            width: 25%;
            flex-shrink: 0;
            flex-grow: 0;
            @include break(tablet) {
                width: 100%;
                margin-top: 16px;
            }
        }

        .btn_container {
            display: flex;
            justify-content: flex-end;
            width: 180px;
            @include break(tablet) {
                width: 100%;
                .btn {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .city, .type {
            display: flex;
            align-items: center;
            @include break(tablet) {
                width: 48%;
                font-size: 15px;
            }

            img {
                width: 25px;
                margin-right: 11px;
                @include break(tablet) {
                    width: 22px;
                }
            }
        }
    }

}