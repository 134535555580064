.module-29-temoignage {

    .tem-slider_slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 85%;
        display: flex;
        justify-content: space-between;
        opacity: 0;
        @include break(mobile) {
            flex-direction: column;
            width: 100%;
        }
        &:first-of-type {
            opacity: 1;
        }

        .img_container {
            width: 205px;
            flex-shrink: 0;
            @include break(mobile) {
                width: 155px;
                margin-bottom: 40px;
            }

            .img {
                width: 100%;
            }
        }
        .content {
            width: 100%;
            flex-shrink: 1;
            padding-left: 10%;
            min-height: 310px;
            @include break(mobile) {
                padding-left: 0%;
            }

            .stars {
                display: flex;
                margin-bottom: 32px;
                @include break(mobile) {
                    margin-bottom: 24px;
                }
            }

            .txt {
                @include font-medium;
                font-size: 1.2em;
                padding-bottom: 100px;
                @include break(mobile) {
                    padding-bottom: 35px;
                    font-size: 1.15em;
                }
            }

            .nom {
                @include font-medium;
                @include break(mobile) {
                    width: 45%;
                }
            }

            .voyage {
                font-size: 13px;
                font-style: italic;
                @include break(mobile) {
                    width: 45%;
                }
            }
        }
    }

    .tem-slider_container {
        position: relative;
        height: 400px;
    }

    .tem-slider {
        position: relative;
        height: 400px;
    }
    
    .tem-slider_nav {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 10%;
        @include break(mobile) {
            right: 0%;
        }
        .arrow {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            position: relative;
            margin-left: 14px;
            transition: all 0.4s;
            cursor: pointer;
            svg {
                transition: all 0.4s;
            }

            &:hover {
                transform: scale(1.1);
                svg {
                    transform: scale(0.8);
                }
            }
        }
    }
}

.post-type-archive-pages_business, .single-pages_business {

    .module-29-temoignage .tem-slider_slide .content .stars svg path { 
       fill: $business;
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-29-temoignage .tem-slider_slide .content .stars svg path { 
        fill: $event;
     }

}