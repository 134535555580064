// Module - 11 - Contenu / Vidéo

.module-11-content_video {
    .video_container {
        max-width: 1100px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        position: relative;
        border-radius: 12px;
        padding-bottom: 50%;
        background-color: $grey;
        @include break(mobile) {
            padding-bottom: 57%;
        }

        img {
            @include bg-img;
        }

        .btn_play {
            @include absolute-center(center);
            background-color: $orange;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 66px;
            height: 66px;
            transition: all 0.4s;
            cursor: pointer;

            svg {
                transition: all 0.4s;
                width: 22px;
            }

            &:hover {
                transform: translate(-50%, -50%) scale(1.1);
                svg {
                    transform: scale(1.4);
                }
            }
        }
    }
}

.post-type-archive-pages_business, .single-pages_business {

    .module-11-content_video .video_container .btn_play {
        background-color: $business;
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-11-content_video .video_container .btn_play {
        background-color: $event;
    }

}