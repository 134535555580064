// Module - 17 - Redirection / Boîtes

.module-17-cta_box {

    .cta-box_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include break(tablet) {
            flex-direction: column;
        }

        .cta_box {
            border-radius: 12px;
            padding: 44px 40px;
            width: 49.5%;
            background-color: $mole;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: all 0.4s;
            @include break(tablet) {
                width: 100%;
                padding: 36px 28px;
                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .top {
                height: 100%;
                flex-shrink: 1;

                .txt {
                    padding-top: $pad-xsmall;
                }
            }
            .bottom {
                margin-top: $pad-small_mobile;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                .img {
                    width: 130px;
                    height: 130px;
                    flex-shrink: 0;
                    border-radius: 12px;
                    overflow: hidden;
                    position: relative;
                    transition: all 0.4s;
                    @include break(mobile) {
                        width: 96px;
                        height: 96px;
                    }
                    img {
                        @include bg-img;
                    }
                }

                .round_btn {
                    width: 66px;
                    height: 66px;
                    border-radius: 50%;
                    background-color: $orange;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.4s;
                    margin-left: auto;
                    @include break(mobile) {
                        flex-shrink: 0;
                        margin-left: 32px;
                        width: 52px;
                        height: 52px;
                    }
                    svg {
                        transition: all 0.4s;
                        path {
                            transition: all 0.4s;
                        }
                    }
                }

            }

            &:hover {
                background-color: $orange;
                color: $white;
                .img {
                    background-color: $white;
                }
                .round_btn { 
                    background-color: $white;
                    transform: scale(1.1);
                    svg {
                        transform: scale(0.8);
                        path {
                            fill: $orange;
                        }
                    }
                }
            }
        }
    }
    
    &.bg_mole {
        .cta-box_container .cta_box {
            background-color: $dark-mole;
        }
    }
}


.post-type-archive-pages_business, .single-pages_business {

    .module-17-cta_box .cta-box_container .cta_box .bottom .round_btn, .module-17-cta_box .cta-box_container .cta_box:hover {
        background-color: $business;
    }
    .module-17-cta_box .cta-box_container .cta_box:hover .round_btn {
        background-color: $white;
    }
    .module-17-cta_box .cta-box_container .cta_box:hover .round_btn svg path {
        fill: $business;
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-17-cta_box .cta-box_container .cta_box .bottom .round_btn, .module-17-cta_box .cta-box_container .cta_box:hover {
        background-color: $event;
    }
    .module-17-cta_box .cta-box_container .cta_box:hover .round_btn {
        background-color: $white;
    }
    .module-17-cta_box .cta-box_container .cta_box:hover .round_btn svg path {
        fill: $event;
    }

}