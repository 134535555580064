.module-34-realisations {
    
    .img_container {
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        width: 100%;
        padding-bottom: 60.5%;
        pointer-events: none;
        .slider-wrap_img {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            .slide_img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                &:first-of-type {
                    opacity: 1;
                }

                img {
                    @include bg-img;
                }
            }
        }
        .filter {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 70.58%, rgba(0, 0, 0, 0.50) 100%);
            z-index: 2;
            pointer-events: none;
        }

        .slider_nav {
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 24px;
            display: flex;
            justify-content: flex-end;
            z-index: 3;
            align-items: center;
            pointer-events: all;

            .nb_slide {
                padding-right: 75px;
                font-size: 15px;
                @include font-medium;
                color: $white;
                @include break(mobile) {
                    padding-right: 0px;
                }
            }

            .arrows  {
                display: flex;
                align-items: center;
                @include break(mobile) {
                    display: none;
                }
            }
            .arrow {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;
                position: relative;
                margin-left: 14px;
                transition: all 0.4s;
                cursor: pointer;
                svg {
                    transition: all 0.4s;
                }
    
                &:hover {
                    transform: scale(1.1);
                    svg {
                        transform: scale(0.8);
                    }
                }
            }
        }
    }

    .slider-wrap_cat {
        margin-top: 30px;
        position: relative;
        margin-bottom: 10px;
        height: 30px;
        pointer-events: none;
        .cat {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            @include font-medium;
            &:first-of-type {
                opacity: 1;
            }
        }
    }

    .slider-wrap_title {
        position: relative;
        height: 50px;
        pointer-events: none;
        h3 {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;
            &:first-of-type {
                opacity: 1;
            }
        }
    }

    .btn {
        margin-top: 60px;
        @include break(mobile) {
            margin-top: 40px;
        }
    }

    .scroll_container {
        display: flex;
        justify-content: space-between;
        @include break(mobile) {
            flex-direction: column;
        }

        .right {
            position: relative;
            width: 205px;
            @include break(mobile) {
                width: 100%;
                margin-bottom: 40px;
            }
            .sibebar {
                position: sticky;
                top: 200px;
                left: 0;
                right: 50px;
                display: flex;
                flex-direction: column;
                transition: all 0.6s;
                @include break(mobile) {
                    position: relative;
                    top: auto!important;
                    right: auto;
                }

                a {
                    font-size: 13px;
                    @include font-medium;
                    color: $grey;
                    margin-bottom: 18px;

                    &:hover, &.active {
                        color: $event
                    }
                }
            }
        }

        .real_content {
            width: 70%;
            flex-shrink: 1;
            @include break(mobile) {
                width: 100%;
            }

            .cat {
                @include font-medium;
                margin-bottom: 10px;
            }

            .img_container {
                margin-top: 32px;
                pointer-events: all;
            }

            .content {
                margin-top: 32px;
                width: 100%;
                margin-bottom: 100px;
                @include break(mobile) {
                    margin-bottom: 60px;
                }

                .info_wrap {
                    display: flex;
                    align-items: center;
                    margin-top: 36px;
                    @include break(mobile) {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-top: 26px;
                    }
                    .info {
                        display: flex;
                        align-items: center;
                        margin-right: 50px;
                        @include font-medium;
                        svg {
                            margin-right: 10px;
                        }
                        @include break(mobile) {
                            margin-right: 0px;
                            margin-bottom: 20px;

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

body.up .module-34-realisations .scroll_container .right .sibebar {
    top: 80px;
}