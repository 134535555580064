// Module – 04 – Contenu / Multi Colonnes

.module-18-blogue {

    .blogue_content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -40px;
        justify-content: space-between;
        flex-wrap: wrap;

        .post_card {
            width: calc(33% - 38px);
            margin-bottom: $pad-med;
            @include break(tablet) {
                width: calc(50% - 42px);
            }
            @include break(mobile) {
                width: 100%;
            }

            .img_container {
                width: 100%;
                padding-bottom: 66.6%;
                position: relative;
                border-radius: 12px;
                overflow: hidden;
                background-color: $orange;

                img {
                    @include bg-img;
                    transition: all 0.4s;
                }

                .tag_wrap {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    display: flex;
                    .tag {
                        border-radius: 6px;
                        background-color: $orange;
                        padding: 5px 8px 3px 8px;
                        margin-left: 8px;
                        font-size: 13px;
                        @include font-medium;
                        color: $white;
                    }
                }
            }

            .content {
                padding-top: 25px;

                .txt {
                    padding-top: $pad-xxsmall;
                    display: -webkit-box;
                    width: 100%;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @include break(tablet) {
                        padding-top: 10px;
                        -webkit-line-clamp: 5;
                    }
                    strong {
                        color: $orange;
                    }
                }

                .info {
                    display: flex;
                    align-items: center;
                    color: $dark-grey;
                    margin-top: 5px;

                    .date {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        padding-right: 12px;
                        svg {
                            margin-bottom: 2px;
                            margin-right: 6px;
                            path {
                                fill: $dark-grey;
                            }
                        }
                    }
                    .time {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        padding-left: 12px;
                        svg {
                            margin-bottom: 2px;
                            margin-right: 6px;
                            path {
                                fill: $dark-grey;
                            }
                        }
                    }
                }

                .link {
                    padding-top: 20px;
                }
            }

            &:hover {
                .img_container img {
                    transform: translate(-50%,-50%) scale(1.05);
                }
                .link {
                    color: $dark-orange;
                    svg {
                    margin-left: 18px;
                    }
                    path {
                    fill: $dark-orange;
                    }
                }
            }
        }

        &.big-col .col {
            width: calc(50% - 42px);
        }
    }

    .btn_wrap {
        display: flex;
        justify-content: center;
        margin-top: 80px;
    }
}


.post-type-archive-pages_business, .single-pages_business {

    .module-18-blogue .blogue_content .post_card .img_container {
        background-color: $business;
    }

}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-18-blogue .blogue_content .post_card .img_container {
        background-color: $event;
    }

}