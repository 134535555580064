.module-31-travel {

    .title_top {
        &.title_top_2col {
            .left {
                width: 45%;
    
                @include break(small-screen) {
                    width: 50%;
                }

                @include break(tablet) {
                    width: 100%;
                }
            }
            
            .title-h2 {
                font-size: 2.6em;

                @include break(small-screen) {
                    font-size: 2.5em;
                }
            }
        }

        p.strong {
            padding-top: 25px;
            @include font-medium;
            color: $grey;
        }
        .btn {
            margin-top: 25px;
        }
    }

    .travel_cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -4%;
        @include break(small-screen) {
            justify-content: center;
        }

        .travel_cards {
            position: relative;
            width: 32.5%;
            margin-bottom: 4%;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            @include break(small-screen) {
               width: 335px;
               margin-left: 10px;
               margin-right: 10px;
            }
            @include break(mobile) {
                margin-left: 0px;
                margin-right: 0px;
            }

            .img_container {
                z-index: 1;
                width: 100%;
                padding-bottom: 55.9%;
                position: relative;
                img {
                    @include bg-img;
                }

                .deal {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    background-color: $orange;
                    border: 1px solid #E58162;
                    padding: 5px 8px 3px 8px;
                    font-size: 13px;
                    @include font-medium;
                    color: $off-white;
                    border-radius: 6px;

                    &.complet {
                        border: 1px solid $light-blue;
                        background-color: $light-blue;
                    }
                }
            }
            .content {
                width: 100%;
                height: 100%;
                flex-shrink: 1;
                background-color: $white;
                border-left: 1px solid #EEEDE2;
                border-right: 1px solid #EEEDE2;
                border-bottom: 1px solid #EEEDE2;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                display: flex;
                flex-direction: column;
                transition: all 0.4s;

               .info_container {
                    padding: 20px 20px 15px 20px;
                    height: 100%;
                    flex-shrink: 1;
                    
                    .title {
                        display: flex;
                        padding-bottom: 18px;
                        @include break(mobile) {
                            flex-direction: column;
                        }

                        h6 {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                            flex-shrink: 1;
                        }

                        .stars {
                            padding-left: 13px;
                            flex-shrink: 0;
                            @include break(mobile) {
                                padding-left: 0px;
                            }
                        }
                    }
                    .info {
                        display: flex;
                        line-height: 160%;
                        @include font-light;
                        margin-bottom: 3px;
                        span {
                            color: $dark-grey;
                            display: block;
                            margin-top: -4px;
                        }
                        img {
                            width: 18px;
                            height: 18px;
                            flex-shrink: 0;
                            margin-right: 6px;
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    .tag_wrap {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 14px;
                        .tag {
                            border-radius: 6px;
                            background-color: $off-white;
                            padding: 5px 8px 3px 8px;
                            font-size: 13px;
                            @include font-medium;
                            color: $orange;
                            margin-right: 8px;
                            margin-bottom: 8px;

                            &.complet {
                                background-color: $blue;
                            }
                        }
                    }

                    .agent_wrap {
                        padding-top: 10px;
                        display: flex; 
                        flex-wrap: wrap;
                        .logo {
                            height: 50px;
                            width: auto;
                            margin-right: 20px;
                        }
                    }
               }

               .price_container {
                    padding: 15px 20px 12px 20px;
                    border-top: 1px solid #EEEDE2;
                    display: flex;
                    min-height: 100px;
                    align-items: center;

                    .left {
                        width: 100%;
                        flex-shrink: 1;
                        .prix-small {
                            font-size: 13px;
                            @include font-light;
                            color: $dark-grey;
                            font-style: italic;
                            margin-bottom: 8px;
                        }
                        .prix {
                            line-height: 80%;
                            color: $dark;
                            span {
                                font-size: 13px;
                            }
                        }
                        .prix_full {
                            font-size: 15px;
                            @include font-medium;
                            color: #C9C9C9;
                            text-decoration: line-through;
                        }
                    }

                    .right {
                        flex-shrink: 0;
                    }

                    // .spin {
                    //     transform-origin: 50%;
                    //     animation:spin 8s linear infinite;
                    // }
                }
            }

            &:hover {
                .content {
                    border-left: 1px solid $orange;
                    border-right: 1px solid $orange;
                    border-bottom: 1px solid $orange;
                }
            }

            &_ghost {
                padding: 0;
                opacity: 0;
                margin: 0;
                height: 1px;
            }
        }
    }

    .travel-tab {
        overflow: hidden;
        .tab_nav {
            overflow-x: scroll;
            overflow-y: hidden;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            padding-bottom: 40px;
            width: 100%;

            .tab_nav-inner {
                display: flex;
                .tab_name {
                    margin-right: 30px;
                    font-size: 1.2em;
                    @include font-medium;
                    flex-shrink: 0;
                    position: relative;
                    transition: all 0.4s;
                    padding-bottom: 9px;
                    cursor: pointer;

                    &::after {
                        content: " ";
                        position: absolute;
                        bottom: 4px;
                        left: 0;
                        width: 0%;
                        height: 4px;
                        background-color: $dark;
                        transition: all 0.4s;
                    }

                    &:hover, &.active {
                        color: $orange;
                        &::after { 
                            width: 100%;
                            background-color: $orange;
                        }
                    }

                    &.active {
                        pointer-events: none;
                    }
                }
                .tab_ghost {
                    flex-shrink: 0;
                    width: calc((100vw - 1325px) / 2);
                    @include break(container){
                        width: 5%;
                    }
                }
            }
        }

        .tab_container {
            position: relative;
            height: 1000px;
            .tab {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                display: none;
                @include break(mobile){
                   flex-direction: column;
                   align-items: center;
                }
                .travel_cards {
                    margin-bottom: 20px;
                    margin-right: 10px;
                    scroll-margin: 0;
                    @include break(mobile){
                        margin-right: 0px;
                    }
                    
                }

                &:first-of-type {
                    display: flex;
                }
            }

            &.nbcol-4 .travel_cards {
                width: 24%;
                font-size: 0.8em;
                @include break(mobile) {
                    width: 100%;
                    font-size: 1em;
                }
            }
        }

    }
}