.module-30-actu {

    .actu_container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content_left {
            padding-left: calc((100vw - #{$container}) / 2);
            padding-right: 5%;
            width: 100%;
            flex-shrink: 1;
            @include break(container){
                padding-left: 5%;
            } 

            .title_top {
                padding-bottom: 40px;
                @include break(tablet){
                    padding-bottom: 20px;
                } 
                .title_top .txt {
                    padding-bottom: 0px!important;
                }
            }

            .actu_link {
                padding: 28px;
                background-color: $mole;
                display: block;
                width: 100%;
                border-radius: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                transition: all 0.4s;
                @include break(mobile){
                    padding: 14px 28px;
                } 

                .top {
                    margin-top: 5px;
                    display: flex;
                    .tag {
                        border-radius: 6px;
                        background-color: $off-white;
                        color: $orange;
                        padding: 5px 8px 3px 8px;
                        font-size: 13px;
                        @include font-medium;
                        margin-right: 8px;
                    }
                }
                .date {
                    font-size: 15px;
                    color: $orange;
                    padding-bottom: 7px;
                    transition: all 0.4s;
                }

                .right {
                    width: 60px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    svg {
                        transition: all 0.4s;
                        opacity: 0;
                        transform: translateX(-20px);
                    }
                }

                &:hover {
                    background-color: $orange;
                    color: $white;
                    .date {
                        color: $white;
                    }
                    .right svg {
                        opacity: 1;
                        transform: translateX(0px);
                    }
                }
            }

            .cta_actu {
                margin-top: 50px;
                @include break(mobile){
                    margin-top: 40px;
                } 
            }
        }

        .img_container {
            flex-grow: 0;
            flex-shrink: 0;
            width: 578px;
            height: 760px;
            border-radius: 12px 0px 0px 12px;
            position: relative;
            overflow: hidden;
            @include break(small-screen) {
                width: 450px;
                height: 630px;
            }
            @include break(tablet) {
                display: none;
            }
            img {
                @include bg-img;
            }
        }
    }

}