// Module - 06 - Contenu / 2 colonnes Text - Image

.module-06-content_col-txt-img {

    .col-txt-img {
        align-items: center;
        @include break(tablet){
            flex-direction: column-reverse;
        }

       .img_container {
            width: 50%;
            @include break(tablet){
                width: 100%;
                padding-top: $pad-large_mobile;
            }

            .img {
                border-radius: 12px;
                padding-bottom: 86.877%;
                position: relative;
                overflow: hidden;
            }

            img {
                @include bg-img;
            }
       }

       .txt_container {
            width: 50%;
            flex-shrink: 1;
            @include break(tablet){
                width: 100%;
            }
            
            h6 {
                padding-top: $pad-xsmall;
            }

            .txt {
                padding-top: $pad-xsmall;
            }
            .btn {
                margin-top: $pad-med;
            }
            .list-title {
                padding-top: $pad-xsmall;
            }
            ul {
                padding-left: 16px;
                padding-top: $pad-xsmall;
                list-style-type: disc;

                li {
                    padding-bottom: 5px;
                    &::marker {
                        color: $orange;
                    }

                    a {
                        color: $orange;
                        transition: all 0.3s;
                        &:hover {
                            color: $dark-orange;
                        }
                    }
                }

                &.link {
                    text-align: left;
                    flex-direction: column;
                    align-items: flex-start;
                    li a {
                        @include font-medium;
                        display: block;
                        svg {
                            vertical-align: top;
                            path {
                                transition: all 0.3s;
                            }
                        }
                        &:hover path {
                            fill: $dark-orange;
                        }
                    }
                }

                &.check {
                    list-style-type: none;
                    li {
                        position: relative;
                        padding-left: 25px;
                        @include break(tablet){
                            padding-left: 20px;
                        }
                        &::before {
                            content: " ";
                            background-image: url('../img/check.svg');
                            background-position: center;
                            background-size: cover;
                            width: 35px;
                            height: 35px;
                            display: block;
                            position: absolute;
                            top: -5px;
                            left: -20px;
                            @include break(tablet){
                                width: 25px;
                                height: 25px;
                                top: -1px;
                                left: -20px;
                            }
                        }
                    }
                }
            }
       }

    }


    &.layout-img_right {
        .col-txt-img {
            flex-direction: row-reverse;
            @include break(tablet){
               flex-direction: column-reverse;
            }
        }
        &.size-grand .img_container {
            padding-right: 20px;
            @include break(tablet){
                padding-right: 0;
            }
        }
        &.size-grand .txt_container {
            padding-left: 5%;
            @include break(tablet){
                padding-left: 0;
            }
        }
        .txt_container {
            padding-right: 7.5%;
            @include break(container){
                padding-right: 5%;
            }
            @include break(tablet){
                padding-right: 0;
            }
        }
    }

    &.layout-img_left {
        flex-direction: row-reverse;
        &.size-grand .img_container {
            padding-left: 20px;
            @include break(tablet){
                padding-left: 0;
            }
        }
        &.size-grand .txt_container {
            padding-right: calc((100vw - #{$container}) / 2);
            @include break(container){
                padding-right: 5%;
            }
            @include break(tablet){
                padding-right: 0;
            }
        }
        .txt_container {
            padding-left: 7.5%;
            @include break(container){
                padding-left: 5%;
            } 
            @include break(tablet){
                padding-left: 0;
            }
        }
    }

    &.size-grand {
        @include break(tablet){
            padding-left: 5%;
            padding-right: 5%;
        }
    }

}



.post-type-archive-pages_business, .single-pages_business {

    .module-06-content_col-txt-img .col-txt-img .txt_container ul li::marker, .module-06-content_col-txt-img .col-txt-img .txt_container ul a {
        color: $business;
    }

    .module-06-content_col-txt-img .col-txt-img .txt_container ul.link li a svg path {
        fill: $business;
    }

    .module-06-content_col-txt-img .col-txt-img .txt_container ul.link li a:hover svg path {
        fill: $dark;
    }

    .module-06-content_col-txt-img .col-txt-img .txt_container ul.check li::before {
        background-image: url('../img/check_business.svg');
    }
}

.post-type-archive-pages_events, .single-pages_events, .post-type-archive-realisations, .single-realisations {

    .module-06-content_col-txt-img .col-txt-img .txt_container ul li::marker, .module-06-content_col-txt-img .col-txt-img .txt_container ul a {
        color: $event;
    }

    .module-06-content_col-txt-img .col-txt-img .txt_container ul.link li a svg path {
        fill: $event;
    }

    .module-06-content_col-txt-img .col-txt-img .txt_container ul.link li a:hover svg path {
        fill: $blue;
    }
    
    .module-06-content_col-txt-img .col-txt-img .txt_container ul.check li::before {
        background-image: url('../img/check_event.svg');
    }
}